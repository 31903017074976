<template>
  <div class="on-con list-con relative" style="height:calc(100% - 51px);">
    <span class="t-lf cor absolute"></span>
    <span class="t-rf cor absolute"></span>
    <span class="b-lf cor absolute"></span>
    <span class="b-rf cor absolute"></span>
    <div class="list-center full-height">
      <div class="on-con-head flex f12 m-b">
        <span class="con-bg text-center text-lightblue m-r-xs p-w-xs wd1"
          >序号</span
        >
        <!-- <span class="flex-one con-bg text-center text-lightblue m-r-xs p-w-xs"
          >发生路段</span
        > -->
        <span class="con-bg text-center text-lightblue m-r-xs p-w-xs wd2"
          >时间</span
        >
        <span class="con-bg text-center text-lightblue m-r-xs p-w-xs flex-one"
          >事件</span
        >
        <!--  <span class="con-bg text-center text-lightblue p-w-xs wd4">来源</span> -->
      </div>
      <vue-scroll :ops="$root.scrollOpsY" style="height: calc(100% - 21px);">
        <div
          class="on-con-body flex text-lightblue m-b-sm"
          v-for="(item, index) in listAlarms"
          @click="clickHandle(item)"
          :key="index"
        >
          <span class="wd1 text-center p-w-xs m-r-xs">{{ index + 1 }}.</span>
          <!--  <span
            class="flex-one ellipsis p-w-xs m-r-xs"
            :title="item.roadName"
            >{{ item.roadName }}</span
          > -->
          <span
            class="wd2  ellipsis text-center p-w-xs m-r-xs"
            :title="item.time"
            >{{ item.time }}</span
          >
          <span
            class="wd3 ellipsis p-w-xs m-r-xs flex-one"
            :title="getDetail(item)"
          >
            {{ getDetail(item) }}</span
          >
          <!--  <span
            class="wd4 ellipsis text-center p-w-xs "
            :title="item.eventSource"
            >{{ item.eventSource }}</span
          > -->
        </div>
        <div v-if="loading" class="full-width flex items-center justify-center">
          <a-spin />
        </div>
      </vue-scroll>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { getDateDiff } from '@/tools';
import { mapState } from 'vuex';
export default {
  name: 'ListCongest',
  props: {
    msg: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      listAlarms: [],
      loading: false,
    };
  },
  computed: {
    ...mapState({
      amapIns: (state) => state.home.amapIns,
    }),
  },
  watch: {
    msg() {
      this.getListAlarm();
    },
  },
  mounted() {
    /*  console.log(this.msg); */
    this._getAlarmList();
  },
  methods: {
    getDetail(item) {
      return `${item.roadCode}${item.roadName}${item.pileNum}发生${item.eventName}事件`;
    },
    clickHandle(item) {
      let cameraInfo = item;
      cameraInfo.lonlat = [cameraInfo.longitude, cameraInfo.latitude];
      /*   if (cameraInfo.cameraStatus == 1) { */
      this.amapIns.cameraInfoWindowShow({
        data: {
          position: [cameraInfo.longitude, cameraInfo.latitude],
          onlineStatus: cameraInfo.status,
          cameraName: cameraInfo.cameraName,
          cameraId: cameraInfo.cameraId,
          cameraNum: cameraInfo.cameraNum,
          organizationId: cameraInfo.organizationId,
          regionCode: cameraInfo.regionCode,
          roadId: cameraInfo.roadId,
          kmPile: cameraInfo.kmPile,
          hmPile: cameraInfo.hmPile,
          classify: cameraInfo.classify,
          cameraType: cameraInfo.cameraType,
          zoomlevel: cameraInfo.zoomlevel,
          source: cameraInfo.source,
          khPile: cameraInfo.kmHmPile,
          collectFlag: 1,
          resolutionValue: 0,
          lonlat: [cameraInfo.longitude, cameraInfo.latitude],
        },
      });
      if (this.$g.isDepart) {
        this.amapIns.addActCameraIcon(cameraInfo);
      }
      /*   } else {
          this.$message.error('该摄像机不在线，不能拉流！');
        } */
    },
    _getAlarmList() {
      this.loading = true;
      this.$api.aiCenterY.queryNewestList().then((res) => {
        if (res.code === 200) {
          this.listAlarms = res.data.map((item) => {
            item.time = moment(item.startTime).format('HH:mm');
            return item;
          });
          this.loading = false;
        }
      });
    },
    getListAlarm() {
      this.loading = true;
      this.$api.aiCenterS.getEvent(this.msg).then(({ data }) => {
        if (data.code === 200) {
          this.listAlarms = data.data.map((item) => {
            item.time = getDateDiff(
              moment(item.timePoke * 1000).format('YYYY-MM-DD HH:mm:ss')
            );
            return item;
          });
          this.loading = false;
        }
        // console.error(this.listAlarms)
      });
    },
    drawLine(item) {
      let lineArr2 = JSON.parse(item.pline).flat(1);
      let center = lineArr2[0];
      this.amapIns.drawLine(lineArr2, center);
    },
  },
};
</script>

<style scoped>
.list-con {
  padding: 18px 0;
}
.list-center .on-con-head {
  line-height: 16px;
}
.list-center .con-bg {
  background: rgba(46, 86, 142, 0.3);
}
.wd1 {
  width: 13%;
}
.wd2 {
  width: 20%;
}
.wd3 {
  width: 20%;
}
.wd4 {
  width: 16%;
}
</style>
