<template>
  <div class="online-statis" style="height:220px">
    <h4 class="relative text-white title m-b">在线率统计</h4>
    <vue-scroll :ops="$root.scrollOpsY" style="height: calc(100% - 36px)">
      <div class="on-con relative">
        <span class="t-lf cor absolute"></span>
        <span class="t-rf cor absolute"></span>
        <span class="b-lf cor absolute"></span>
        <span class="b-rf cor absolute"></span>
        <div class="on-con-center">
          <online-chart :chartFormateData="chartFormateData"></online-chart>
        </div>
      </div>
      <!-- <div class="flex items-center box-info text-lightblue m-t-md">
        <div class="flex-one text-center m-r pointer">
          <span class="text-white f32 m-r-xs">58</span>人正在调取视频
        </div>
        <div class="flex-one text-center pointer" @click="goOutProvince()">
          <span class="text-white f32 m-r-xs">18</span>省正在调取视频
        </div>
      </div> -->
    </vue-scroll>
  </div>
</template>

<script>
import OnlineChart from './OnlineChart';
export default {
  name: 'OnlineStatis',
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  components: {
    OnlineChart,
  },
  computed: {
    chartFormateData() {
      let total = 0,
        offline = 0,
        online = 0,
        inerror = 0;
      this.data.forEach((vo) => {
        total += vo.total;
        offline += vo.offline;
        online += vo.online;
        inerror += vo.inerror;
      });

      return { total, offline, online, inerror };
      /* this.$set(this.chartFormateDataObj, 'total', total);
      this.$set(this.chartFormateDataObj, 'offline', offline);
      this.$set(this.chartFormateDataObj, 'online', online);
      this.$set(this.chartFormateDataObj, 'inerror', inerror); */
    },
  },
  mounted() {
    // console.error(this.data);
  },
  watch: {
    /* data: {
      handler() {
        this.chartFormateData();
      },
      deep: true,
    }, */
  },
  methods: {
    goOutProvince() {
      this.$router.push({ path: '/home/outside-province-video' });
    },
  },
};
</script>

<style scoped>
.online-statis {
  height: calc(100% - 340px);
  width: 300px;
}
.title {
  padding-left: 12px;
}
.title::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 3px;
  height: 19px;
  background: #96b9e4;
}
.on-con {
  border-top: 1px dashed #28456a;
  border-bottom: 1px dashed #28456a;
}
.on-con .cor {
  border-color: #96b9e4;
  width: 9px;
  height: 9px;
}

.on-con-center {
  height: 100%;
}
.box-info > div {
  height: 64px;
  line-height: 64px;
  background: linear-gradient(
    90deg,
    rgba(46, 86, 142, 0.5),
    rgba(25, 45, 80, 0.5)
  );
}
</style>
