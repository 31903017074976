<template>
  <div class="home-right p-sm">
    <home-right-top />
    <template>
    <home-right-bottom v-if="$g.regionCode !== 530000"/>
    <home-right-bottom-yn v-else/>
    </template>
  </div>
</template>

<script>
import HomeRightTop from './HomeRightTop';
import HomeRightBottom from './HomeRightBottom';
import HomeRightBottomYn from './HomeRightBottomYn';
export default {
  name: 'HomeRight',
  data() {
    return {};
  },
  components: {
    HomeRightTop,
    HomeRightBottom,
    HomeRightBottomYn
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style scoped>
.home-right {
  background: linear-gradient(
    0deg,
    rgba(15, 40, 61, 0.3),
    rgba(46, 86, 142, 0.3)
  );

  height: 100%;
  color: #fff;
}
</style>
