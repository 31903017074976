<template>
  <div class="p-sm full-width full-height scroll">
    <div class="title-con p-b-sm">
      <span class="pointer text-lightblue flex items-center"   @click="addSharingFolderHandle"
        ><img
          src="~@/assets/resource/collect-add.png"
          width="20"
          class="m-r-xs"
        />新增共享夹</span
      >
    </div>
    <div class="m-t-xs">
      <div class="folder-wrapper">
        <drop
          class="folder m-b-sm pointer"
          v-for="(folder,index) in sharingFolders"
          :key="folder.folderId"
          @drop="addCamera($event, index, folder.folderId)"
       
        >
          <div
            class="folder-hd flex justify-between items-center text-lightblue f15 m-b-sm"
            @click="getSharingCamera(folder.folderId, index)"
          >
            <div class="text-white flex ">
              <span class="weight flex items-center" :title="folder.folderName"
                ><!-- <a-icon type="folder" class="m-r-xs" /> --><img
                  src="~@/assets/resource/collect-folder.png"
                  width="20"
                  class="m-r-xs"
                />{{ folder.folderName }}</span
              >
            </div>
            <div class="flex items-center">
              <a-icon type="youtube" class="m-r-sm" v-show="folder.status === '2'" @click.stop.prevent="shareFolderHandle(folder,index)"/>
              <a-icon type="pause-circle" class="m-r-sm"  v-show="folder.status === '1'"  @click.stop.prevent="unshareFolderHandle(folder,index)"/>
              <span>
                <a-icon
                  type="edit"
                  title="修改"
                  class="pointer m-r-sm"
                  @click.stop.prevent="handleEditFavorit(folder,index)"
                />
                <a-icon
                  type="rest"
                  title="删除"
                  class="pointer"
                  @click.stop.prevent="
                    deleteFolder(folder,index)
                  "
                />
              </span>
            </div>
          </div>
         <template v-if="folder.show && folder.list && folder.list.length">
              <div
              class="flex items-center m-b-sm p-w-sm"
              v-for="(item,cameraIndex) in folder.list"
              :key="item.cameraNum"
              :title="item.cameraName"
            >
              <span
                :class="[
                  'icon-style  m-r-xs flex-s',
                  {
                    online: item.cameraStatus === '1',
                    offline: item.cameraStatus === '0',
                    error: item.cameraStatus === '2',
                    yunOffline: item.cameraStatus === '0' && $g.treeDefinate,
                  },
                ]"
              ></span>
              
              <span
                class="flex-one  pointer ellipsis"
                style="white-space:nowrap"
                @click.stop.prevent="selectShot(item)"
                > {{
                  item.cameraName
                }}</span
              >
              <a-icon type="arrow-up" 
                title="上移"
                class="pointer m-l-sm"
                :class="{'disabled':cameraIndex === 0}"
                @click.stop.prevent="
                    upHandle(folder,item,index,cameraIndex)
                  "
                />
              <a-icon type="arrow-down" 
                title="下移"
                class="pointer m-l-sm"
                :class="{'disabled':cameraIndex === folder.list.length - 1}"
                @click.stop.prevent="
                    downHandle(folder,item,index,cameraIndex)
                  "
                />
              <a-icon
                  type="rest"
                  title="删除"
                  class="pointer m-l-sm"
                  @click.stop.prevent="
                    deleteCamera(folder,item,index,cameraIndex)
                  "
                />
            </div>
          </template> 
        </drop>
      </div>
    </div>
     <sharing-toggle
      :visible="sharingVisible"
      :edit="isEdit"
      :folderId="folderId"
      :folderName="folderName"
      @close="closeSharing"
      @sure="sureSaring"
      @editsure="editsureHandle"
    />
  </div>
</template>

<script>
import { Drop } from 'vue-drag-drop';
import { mapActions, mapState, mapMutations } from 'vuex';
import SharingToggle from '@/components/SharingToggle';
import { CODE_OK } from '@/request/config_code'
export default {
  data() {
    return {
      homeFolderCamera: [
        /* {
          folderId: 0,
          folderName: '重点路段',
          cameraNumber: 10,
          cameraList: [],
        },
        {
          folderId: 1,
          folderName: '桥隧',
          cameraNumber: 10,
          cameraList: [],
        }, */
      ],
      isEdit:false,
      folderId:'',
      folderName:'',
      sharingVisible:false,
      currentFolderIndex:0,
      // sharingPointCamera:{}
    };
  },
  components: {
      Drop,
      SharingToggle
  },
  computed:{
    ...mapState({
      sharingFolders: state => state.sharing.sharingFolders,
      amapIns: (state) => state.home.amapIns,
      sharingPointCamera:(state) => state.sharing.sharingPointCamera,
    }),
  },
  mounted(){
    this.getSharingFolder({currPage:0,pageSize:0})
  },
  methods: {
    ...mapActions(['getSharingFolder','addSharingFolder', 'deleteSharingFolder', 'bindingCamera','getCamerasByFolderId','unboundCamera','start','stop']),
    ...mapMutations(['setSharingFolders','setSharingPointCamera']),
    //判断是否共享摄像机
    async judgeCamera(folder) {
      const res = await this.getCamerasByFolderId(folder.folderId);
      return {isbind:res.data.length>0,bindCameraList:this.bindPoint(res.data)}
    },
    //绑定点位
    bindPoint(data) {
      if(!data||data.length<0){
        return []
      }
      return data.map(item=>{
              item.point = [item.longitude,item.latitude]
              return item;
            });
    },
    //共享绑定摄像机
    async shareFolderHandle(folder,index) {
      const {isbind,bindCameraList} = await this.judgeCamera(folder)
      if(!isbind) {
        this.$message.error('该共享夹没有绑定的摄像机！')
        return;
      }
       
      //判断是否有绑定摄像机
      this.$confirm({
        title: '提示',
        content: '共享后，将更新共享平台播放内容，确定开始共享吗？',
        onOk: () => {
          this.start(folder.folderId).then(res=>{
            if(res.code === CODE_OK){
              let sharingFolders = [...this.sharingFolders]
              sharingFolders = sharingFolders.map(item=>{
                item.status = '2'
                return item
              })
              let currentFolder = sharingFolders[index]
              currentFolder.status = '1'
              this.setSharingFolders(sharingFolders)
              this.$message.success('共享成功！')
              /* this.getCamerasByFolderId(folder.folderId).then(res => {
                if(res.code === CODE_OK) {
                  const list = res.data.map(item=>{
                    item.point = [item.longitude,item.latitude]
                    return item;
                  }) */
                  // let sharingPointCamera = JSON.parse(JSON.stringify(this.sharingPointCamera))
                  let sharingPointCamera = bindCameraList
                  // const sharingPointFormat = this.formatPoint(sharingPointCamera)
                  this.setSharingPointCamera(sharingPointCamera)
                  this.$nextTick(() => {
                      // this.amapIns.setSharePoint(sharingPointFormat);
                      this.amapIns.setSharePoint(sharingPointCamera);
                  });
                
              /*   }
              }); */
              //  this.amapIns.setPoint(item.pline);
            }
          })
        },
        oncancel: () => {
          
        }
      });
    },
    //格式化点位信息
    formatPoint(sharingPointCamera){
      if(!sharingPointCamera || JSON.stringify(sharingPointCamera) === '{}') {
        return []
      }
      let arr = []
      for(let key in sharingPointCamera) {
        arr = arr.concat(sharingPointCamera[key])
      }
      return arr;
    },
    async unshareFolderHandle(folder,index) {
      const {isbind} = await this.judgeCamera(folder)
      if(!isbind) {
        this.$message.error('该共享夹没有绑定的摄像机！')
        return;
      }
      this.$confirm({
        title: '提示',
        content: '停止后，将取消共享平台播放内容，确定停止共享吗?',
        onOk: () => {
          this.stop(folder.folderId).then(res => {
            if(res.code === CODE_OK){
              let sharingFolders = [...this.sharingFolders]
              let currentFolder = sharingFolders[index]
              currentFolder.status = '2'
              this.setSharingFolders(sharingFolders)
              this.$message.success('停止共享成功！')

              // let sharingPointCamera = JSON.parse(JSON.stringify(this.sharingPointCamera))
              // delete sharingPointCamera[folder.folderId]
              // const sharingPointFormat = this.formatPoint(sharingPointCamera)
              this.setSharingPointCamera([])
              this.$nextTick(() => {
                 this.amapIns.setSharePoint([]);
              });
             /*  this.$nextTick(() => {
                this.amapIns.removeDrawMarker();
              }) */
            }
          })
        },
        oncancel: () => {}
      });
    },
    //新增共享夹回调关闭
    closeSharing() {
      this.sharingVisible = false;
    },
    //新增共享夹回调确定
    sureSaring() {
      this.sharingVisible = false;
      this.$message.success(`${this.isEdit ? '编辑' : '新增'}共享夹成功！`);
      
    },
    //更新
    editsureHandle(folderName) {
      this.sharingVisible = false;
      this.$message.success(`${this.isEdit ? '编辑' : '新增'}共享夹成功！`);
      
      let folderList = [...this.sharingFolders];
      folderList[this.currentFolderIndex].folderName = folderName;
      this.setSharingFolders(folderList);
       
    },
    //添加共享夹目录
    addSharingFolderHandle() {
      this.sharingVisible = true;
      this.isEdit = false;
      this.folderId = '';
      this.folderName = '';
    },
    //处理编辑共享夹
    handleEditFavorit(folder,index) {
      this.sharingVisible = true;
      this.isEdit = true;
      this.folderId = folder.folderId;
      this.folderName = folder.folderName;
      this.currentFolderIndex = index;
    },
    deleteFolder(folder, index) {
       this.$confirm({
        title: '提示',
        content: '删除操作不可恢复，确认删除该共享夹吗?',
        onOk: () => {
          this.deleteSharingFolder(folder.folderId).then(res=>{
            if(res.code == CODE_OK) {
              let folderList = [...this.sharingFolders];
              folderList.splice(index,1)
              this.setSharingFolders(folderList);
              this.$message.success('删除共享夹成功')
            }
          })
        },
        onCancel: () => {},
      })
    },
    shareHandle() {},
    //获取共享夹下所有摄像机
    getSharingCamera(folderId,index){
      let sharingFolders = [...this.sharingFolders]
      let currentSharingFolders = sharingFolders[index]
  
      if(currentSharingFolders.show&&currentSharingFolders['list']?.length>0){
        currentSharingFolders.show = false
        this.setSharingFolders(sharingFolders)
      }else if(!currentSharingFolders.show&&currentSharingFolders['list']?.length>0){
        
        currentSharingFolders.show = true
        this.setSharingFolders(sharingFolders)
      }else{
        this.getCamerasByFolderId(folderId).then(res => {
        if(res.code === CODE_OK) {
           console.log(res.data)
     
           currentSharingFolders.list = res.data ? res.data : []
           currentSharingFolders.show = currentSharingFolders.list.length > 0
           this.setSharingFolders(sharingFolders)
        }
      });
      }
      
    },
    addCamera($event, index, folderId){
      console.log($event,index);
      // debugger;
      // eslint-disable-next-line no-unused-vars
      let params = {
        cameraNum: $event.cameraNum,
        folderId
      }
      this.bindingCamera(params).then(res => {
        if(res.code === CODE_OK) {
          let cameraInfo = {
            cameraName:$event.name,
            cameraStatus:$event.onlineStatus,
            cameraNum:$event.cameraNum,
            collectFlag:$event.collectFlag,
            latitude:$event.position[1],
            longitude:$event.position[0]
          }
          let sharingFolders = [...this.sharingFolders];
          sharingFolders[index]['list'] = sharingFolders[index]['list']? sharingFolders[index]['list'] : []
          sharingFolders[index]['list'].push(cameraInfo);
          
          sharingFolders[index]['show'] = true;
          this.setSharingFolders(sharingFolders)
          /* let cameraList = this.sharingFolders[index]['list']
          this.$set(this.homeFolderCamera[index],'show',true)
          this.$set(this.homeFolderCamera[index],'list',cameraList) */
        }
      })
    },
    deleteCamera(folder,camera,folderIndex,cameraIndex) {
      let params = {
        folderId:folder.folderId,
        cameraNum:camera.cameraNum,
      }
      this.$confirm({
        title: '提示',
        content: '确定从当前共享夹中移除此摄像机吗?',
        onOk: () => {
          this.unboundCamera(params).then(res=>{
            if(res.code === CODE_OK){
              let sharingFolders = [...this.sharingFolders]
              let currentSharingFolder = sharingFolders[folderIndex]
              currentSharingFolder.list.splice(cameraIndex,1)
              currentSharingFolder.show = currentSharingFolder.list.length > 0
              this.setSharingFolders(sharingFolders)
            }
          })
        },
        onCancel: () => {}
      })
    },
    selectShot(item) {
        
        let cameraInfo = item;
        cameraInfo.lonlat = [cameraInfo.longitude, cameraInfo.latitude];
        this.amapIns.cameraInfoWindowShow({
          data: {
            position: [cameraInfo.longitude, cameraInfo.latitude],
            onlineStatus: cameraInfo.cameraStatus,
            cameraName: cameraInfo.cameraName,
            cameraId: cameraInfo.cameraId,
            cameraNum: cameraInfo.cameraNum,
            // organizationId: cameraInfo.organizationId,
            // regionCode: cameraInfo.regionCode,
            // roadId: cameraInfo.roadId,
            // kmPile: cameraInfo.kmPile,
            // hmPile: cameraInfo.hmPile,
            // classify: cameraInfo.classify,
            // cameraType: cameraInfo.cameraType,
            // zoomlevel: cameraInfo.zoomlevel,
            // source: cameraInfo.source,
            // khPile: cameraInfo.kmHmPile,
            collectFlag: cameraInfo.collectFlag,//收藏
            resolutionValue:  0,//高标清
            lonlat: [cameraInfo.longitude, cameraInfo.latitude],
          },
        });
        if (this.$g.isDepart) {
          this.amapIns.addActCameraIcon(cameraInfo);
        } 
        
      
    },
    // 上移
    // eslint-disable-next-line no-unused-vars
    upHandle(folder,item,index,cameraIndex) {
      if(cameraIndex === 0) {
        return;
      }
      let tempItem = folder.list[cameraIndex-1];
      /* folder.list[cameraIndex-1] = item;
      folder.list[cameraIndex] = tempItem;  */
      this.$set(folder.list,cameraIndex-1,item);
      this.$set(folder.list,cameraIndex,tempItem);
      this.$api.sharingFolder.updateRankByFolderId(folder.folderId,folder.list).then(res=>{
        const {code} = res;
        if(code !== CODE_OK) {
          this.$message.error('接口调用失败');
        }
      });
    },
    // 下移
    // eslint-disable-next-line no-unused-vars
    downHandle(folder,item,index,cameraIndex) {
      if(cameraIndex === folder.list.length-1) {
        return;
      }
      let tempItem = folder.list[cameraIndex+1];
      this.$set(folder.list,cameraIndex+1,item);
      this.$set(folder.list,cameraIndex,tempItem);
      this.$api.sharingFolder.updateRankByFolderId(folder.folderId,folder.list).then(res=>{
        const {code} = res;
        if(code !== CODE_OK) {
          this.$message.error('接口调用失败');
        }
      })
    }
    
  },
};
</script>

<style scoped>
.title-con {
  border-bottom: 1px dashed rgba(40, 69, 106, 1);
}
.scroll {
  overflow: auto;
}
.p-b-sm{
  padding-bottom: 10px;
}
.disabled{
  cursor: not-allowed;
  pointer-events: none;
  color:rgb(151, 151, 151);
}
</style>
