<template>
  <div class="flex items-center p-sm">
    <div id="chart"></div>
    <div class="item-info flex-one">
      <div
        :class="[
          'item-info-li flex items-center m-b-sm',
          {
            offline: item.type === 'offline',
            error: item.type === 'inerror',
            yunanOffline: item.type === 'offline' && $g.treeDefinate,
          },
        ]"
        v-for="(item, index) in itemInfoList"
        :key="index"
      >
        <div class="circle text-white text-center">
          <span>{{ item.percent }}<span class="per">%</span></span>
        </div>
        <div class="flex-one flex-column flex justify-center count-wrap">
          <span class="text-online">{{
            item.type === 'online'
              ? '在线'
              : item.type === 'offline'
              ? '离线'
              : '故障'
          }}</span>
          <span class="text-white">{{ item.count }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from '@/tools/echarts';
let itemInfoListArr = [
  {
    id: 0,
    percent: 85,
    type: 'online',
    count: 13412,
  },
  {
    id: 1,
    percent: 10,
    type: 'offline',
    count: 1412,
  },
  {
    id: 2,
    percent: 5,
    type: 'inerror',
    count: 712,
  },
];
export default {
  name: 'OnlineChart',
  props: {
    chartFormateData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      itemInfoList: itemInfoListArr,
      myChart:null,
      options: {}
    };
  },
  computed: {},
  watch: {
    chartFormateData: {
      handler() {
        this.renderChart();
      },
      deep: true,
    },
  },
  activated() {
     this.myChart.dispose();
     this.$nextTick(() => {
       this.renderChart();
     }); 
  },
  created() {
    let newItemInfoListArr = itemInfoListArr.slice();
    if (this.$g.isHideException) {
      newItemInfoListArr.pop();
    }
    this.itemInfoList = newItemInfoListArr;
  },
  mounted() {
    this.renderChart();
  },
  methods: {
    renderChart() {
      let vm = this;
      let obj = document.getElementById('chart'); //"abnormal-chart"
      let data = this.chartFormateData;
      // console.error(data);
      if (!obj) {
        return false;
      }
      //console.log("pieChartData=>", pieChartData, pieChartDataTotal);
      this.myChart = echarts.init(obj);
      this.options = {
        tooltip: {
          show: false,
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)',
        },
        title: {
          text: data.total,
          x: '47%',
          y: '44%',
          textAlign: 'center',
          textStyle: {
            fontSize: 22,
            fontWeight: 'normal',
            color: '#ffffff',
          },
          triggerEvent: true,
        },
        grid: {
          top: '0px',
          left: '0px',
          right: '0px',
          bottom: '0px',
        },
        series: [
          {
            type: 'pie',
            radius: ['78%', '100%'],
            x: 'center',
            label: {
              show: false,
            },
            top: '10%',
            left: '10%',
            bottom: '10%',
            right: '10%',
            labelLine: {
              show: false,
            },
            color: [
              '#78b1f9',
              vm.$g.treeDefinate ? '#ff6d55' : '#c4b9b9',
              '#ee8987',
            ],
            data: [data.online, data.offline, data.error],
          },
        ],
      }
      //var that = this;
      // 绘制图表
      this.myChart.setOption(this.options,true);
      window.addEventListener('resize', () => {
        this.myChart.resize();
      });
      this.formatList(data);
    },
    formatList(data) {
      let totalPer = 0;
      
      this.itemInfoList = this.itemInfoList.map((item, index) => {
        item.count = data[item.type];
        item.percent =
          data['total'] === 0
            ? 0
            : parseFloat((item.count / data['total']) * 100).toFixed(1);
        /* if (index == 1) {
          item.percent = data['total'] === 0 ? 0 : 100 - totalPer;
        }
        totalPer += item.percent; fixsxy*/
        if (index != 0) {
          totalPer += +item.percent;
        }
        return item;
      });
      // console.error('pp--', data, this.itemInfoList);
      let per = data['total'] === 0 ? 0 : (100 - totalPer).toFixed(1);
      this.itemInfoList[0].percent = per;
    },
  },
};
</script>

<style scoped>
#chart {
  width: 150px;
  height: 150px;
  flex-shrink: 0;
}
.item-info-li {
  background: rgba(58, 104, 159, 0.16);
  border-radius: 22px;
  width: 86%;
}
.item-info-li .circle {
  width: 44px;
  height: 44px;
  line-height: 44px;
  font-size: 16px;
  /* background: linear-gradient(0deg, #213c62, #386499); */
  background: #78b1f9;
  border-radius: 100%;
  margin-right: 10px;
  flex-shrink: 0;
}
.item-info-li .circle .per {
  font-size: 12px;
  vertical-align: 1px;
}
.item-info-li .count-wrap {
  line-height: 1.2;
}
.item-info-li .text-online {
  color: #78b1f9;
}
.item-info-li.offline,
.item-info-li.yunanOffline {
  background: rgba(196, 185, 185, 0.16);
}
.item-info-li.error {
  background: rgba(238, 137, 135, 0.16);
}
.item-info-li.offline .circle {
  /* background: linear-gradient(0deg, #495268, #bbb2b3); */
  background: #7a7a7a;
}
.item-info-li.yunanOffline .circle {
  background: #ff6d55;
}
.item-info-li.error .circle {
  /* background: linear-gradient(0deg, #55445b, #e98786); */
  background: #ff6f57;
}
.item-info-li.offline .text-online {
  color: #c4b9b9;
}
.item-info-li.error .text-online {
  color: #ee8987;
}
.item-info-li.yunanOffline .text-online {
  color: #ff6d55;
}
</style>
