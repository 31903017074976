<template>
  <div class="full-height block-route">
    <!--style="height:calc(100% - 272px);"-->
    <div v-hasPermiss="'10921016'" class="ht-bottom-style full-height">
      <div class="flex hd items-center">
        <span class="m-r-sm text-white">拥堵路段</span>
        <a-dropdown :trigger="['click']">
          <span class="ant-dropdown-link text-lightblue pointer">
            {{ currentRouteType }} <a-icon type="down" />
          </span>
          <a-menu slot="overlay">
            <a-menu-item
              v-for="item in routeType"
              :key="item.id"
              @click="handleRouteType(item)"
            >
              {{ item.text }}
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
      <div class="select-type m-t-sm m-b-sm">
        <div class="flex items-center">
          <a-select
            class="flex-one m-r-xs"
            placeholder="道路等级"
            v-if="false"
            size="small"
            @change="handleRoutRankChange"
          >
            <a-select-option
              v-for="item in routeRank"
              :value="item.value"
              :key="item.id"
            >
              {{ item.text }}
            </a-select-option>
          </a-select>
          <a-select
            class="flex-one m-r-xs"
            placeholder="道路长度"
            size="small"
            allowClear
            @change="handleLengthChange"
          >
            <a-select-option
              v-for="item in routeLength"
              :value="item.value"
              :key="item.id"
            >
              {{ item.text }}
            </a-select-option>
          </a-select>
          <a-select
            class="flex-one"
            placeholder="持续时长"
            size="small"
            allowClear
            @change="handleDurationChange"
          >
            <a-select-option
              v-for="item in routeTime"
              :value="item.value"
              :key="item.id"
            >
              {{ item.text }}
            </a-select-option>
          </a-select>
        </div>
        <div class="m-t-sm" v-if="false">
          <a-select
            class="full-width"
            placeholder="持续时长"
            size="small"
            @change="handleDurationChange"
          >
            <a-select-option
              v-for="item in routeTime"
              :value="item.value"
              :key="item.id"
            >
              {{ item.text }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="scroll" ref="scrollWrapper">
        <div
          class="select-item flex items-center min-wd pointer "
          :class="{ current: item.check }"
          v-for="item in routeList"
          :key="item.congestionId"
          @click="clickHandle(item)"
        >
          <div class="select-item-fl flex-one flex items-center min-wd m-r-sm">
            <span
              :class="`select-item-block  flex-shrink m-r-xs rank${item.state}`"
              >堵</span
            >
            <div class="select-item-info flex-one min-wd">
              <div class="select-item-hd full-width flex items-center">
                <p class="ellipsis full-width title-color">
                  {{ item.roadName }}
                </p>
                <span :class="`trend trend${item.state}`"></span>
              </div>
              <div
                class="select-item-bd flex items-center text-color f12 flex justify-between"
              >
                <span class="text-center"
                  ><!-- 时速<br /> --><i class="i-speed i-s"></i
                  >{{ getSpeed(item.speed) }}km/h</span
                >
                <span class="text-center"
                  ><!-- 拥堵<br /> --><i class="i-block i-s"></i
                  >{{ getDistance(item.distance) }}km</span
                >
                <span class="text-center"
                  ><!-- 持续<br /> --><i class="i-duration i-s"></i
                  >{{ getDurationMin(item.durationMin) }}
                </span>
              </div>
            </div>
          </div>
          <span class="flex-shrink time text-lightblue">{{
            getBlockStartTime(item.initTime)
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { CODE_OK } from '@/request/config_code';
import moment from 'moment';
export default {
  name: 'HomeRightBottomYn',
  computed: {
    ...mapState({
      amapIns: (state) => state.home.amapIns,
      // routeList: (state) => state.home.routeList,
    }),
    routeListFomat() {
      return this.routeList.map((item) => {
        item.check = false;
        return item;
      });
    },
  },
  data() {
    return {
      routeList: [],
      currentRouteType: '最新',
      routeRank: [
        {
          id: 0,
          value: 0,
          text: '高速公路',
        },
        {
          id: 1,
          value: 1,
          text: '国道',
        },
        {
          id: 2,
          value: 2,
          text: '省道',
        },
        {
          id: 3,
          value: 3,
          text: '县道',
        },
        {
          id: 4,
          value: 4,
          text: '乡公路',
        },
        {
          id: 5,
          value: 5,
          text: '县乡村内部道路',
        },
        {
          id: 6,
          value: 6,
          text: '主要大街、城市快速道',
        },
        {
          id: 7,
          value: 7,
          text: '主要道路',
        },
        {
          id: 8,
          value: 8,
          text: '次要道路',
        },
        {
          id: 9,
          value: 9,
          text: '普通道路',
        },
      ],
      routeLength: [
        {
          id: 0,
          value: 200,
          text: '200米',
        },
        {
          id: 1,
          value: 400,
          text: '400米',
        },
        {
          id: 2,
          value: 500,
          text: '500米',
        },
        {
          id: 3,
          value: 800,
          text: '800米',
        },
        {
          id: 4,
          value: 1000,
          text: '1千米',
        },
        {
          id: 5,
          value: 2000,
          text: '2千米',
        },
        {
          id: 6,
          value: 3000,
          text: '3千米',
        },
        {
          id: 7,
          value: 5000,
          text: '5千米',
        },
        {
          id: 8,
          value: 10000,
          text: '10千米',
        },
      ],
      routeTime: [
        {
          id: 0,
          value: 4,
          text: '超过4分钟',
        },
        {
          id: 1,
          value: 10,
          text: '超过10分钟',
        },
        {
          id: 2,
          value: 30,
          text: '超过30分钟',
        },
        {
          id: 3,
          value: 45,
          text: '超过45分钟',
        },
        {
          id: 4,
          value: 60,
          text: '超过1小时',
        },
        {
          id: 5,
          value: 120,
          text: '超过2小时',
        },
        {
          id: 6,
          value: 240,
          text: '超过4小时',
        },
        {
          id: 7,
          value: 360,
          text: '超过6小时',
        },
        {
          id: 8,
          value: 480,
          text: '超过8小时',
        },
        {
          id: 9,
          value: 600,
          text: '超过10小时',
        },
        {
          id: 10,
          value: 720,
          text: '超过12小时',
        },
      ],
      routeType: [
        {
          id: 0,
          value: 1,
          text: '最新',
        },
        {
          id: 1,
          value: 2,
          text: '时长',
        },
        {
          id: 2,
          value: 3,
          text: '距离',
        },
      ],
      adCodes: ['530100'],
      order: 1,
      queryList: [
        {
          congestRate: 0,
          congestType: 0,
          distance: 0,
          duration: 0,
          roadType: 0,
        },
      ],
      currentRoadType: 0,
      currentDistance: 0,
      currentDuration: 0,
      flag: true,
      apiTimer: null,
      timer: null,
      load: true,
      pageNum: 1,
      pageSize: 30,
      totalData: 0,
      newRouteList: [],
      isLoad: true,
    };
  },
  mounted() {
    this._getCongestedSection();
    if (this.$refs.scrollWrapper) {
      this.$refs.scrollWrapper.addEventListener('scroll', () => {
        this.timer && clearTimeout(this.timer);

        this.timer = setTimeout(() => {
          this.scroll();
        }, 300);
      });
    }
  },
  methods: {
    ...mapMutations(['setBlockRouteList']),
    ...mapActions(['getRouteList']),
    //获取盒子被卷起来的高度
    scrollTop() {
      return this.$refs.scrollWrapper ? this.$refs.scrollWrapper.scrollTop : 0;
    },
    //获取视口高度
    boxClientHeight() {
      return this.$refs.scrollWrapper
        ? this.$refs.scrollWrapper.clientHeight
        : 0;
    },
    //获取盒子高度
    boxHeight() {
      return this.$refs.scrollWrapper
        ? this.$refs.scrollWrapper.scrollHeight
        : 0;
    },
    scroll() {
      // console.log(1,this.scrollTop(),this.boxClientHeight(),this.boxHeight())
      if (
        this.scrollTop() + this.boxClientHeight() >=
        this.boxHeight() - 50 /*滚动响应区域高度取50px*/
      ) {
        if (this.isLoad) {
          this.pageNum++;
        } else {
          this.pageNum = 1;
        }

        if (this.pageNum > this.totalData /*  || !this.isLoad */) {
          return;
        }
        this._getCongestedSection();
      }
    },
    //获取拥堵路段
    _getCongestedSection() {
      this.apiTimer && clearTimeout(this.apiTimer);
      this.queryList[0].distance = this.currentDistance;
      this.queryList[0].duration = this.currentDuration;
      this.queryList[0].roadType = this.currentRoadType;
      const params = {
        // adCodes: this.adCodes,
        order: this.order,
        queryList: this.queryList,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      /*this.getRouteList(params).then(({ code, data }) => {
        this.$nextTick(() => {
          if (code === CODE_OK) {
            this.newRouteList = data.list.filter(li => {
              if(li.initTime - Date.now() <= 2*60*1000){
                return true;
              }
            });
            if(this.newRouteList.length) {
              if(!this.load){
                this.load = true;
              }
            }  
            if (this.load) {
              this.amapIns && this.amapIns.addBlockRoute(data.list);
              this.load = false;
            }
          }
        });
        this.timer = setTimeout(() => {
          this._getCongestedSection();
        }, 2 * 60 * 1000);
      });*/
      this.$api.home
        .getCongestedSection(params)
        .then((res) => {
          const { code, data } = res;
          if (code === CODE_OK) {
            if (this.pageNum === 1) {
              this.routeList = data?.list;
            } else {
              const congestionIds = data?.list.map((dl) => dl.congestionId);
              const routeList = this.routeList.filter(
                (item) => !congestionIds.includes(item.congestionId)
              );
              this.routeList = routeList.concat(data?.list);
            }
            this.routeList = this.routeList.map((item) => {
              this.$set(item, 'check', false);
              return item;
            });
            this.totalData = data.totalPage;
            this.isLoad = this.pageNum >= this.totalData ? false : true;
            this.setBlockRouteList(this.routeList);
            const blockRoute = this.routeList.filter(
              (item) => item.state === 4
            );
            this.amapIns && this.amapIns.addBlockRoute(blockRoute);
          } else {
            this.$message.error('获取拥堵路段失败');
          }
          this.apiTimer = setTimeout(() => {
            this.timer && clearTimeout(this.timer);
            this.isLoad = false;
            this.pageNum = 1;
            // this.routeList = [];
            // this.setBlockRouteList(this.routeList);
            this._getCongestedSection();
          }, 2 * 60 * 1000);
        })
        .catch(() => {
          this.$message.error('获取拥堵路段失败');
        });
    },
    //拥堵等级
    handleRouteType(item) {
      this.currentRouteType = item.text;
      this.order = item.value;
      this.pageNum = 1;
      this._getCongestedSection();
    },
    handleChange() {},
    //点击拥堵路段在地图上展示拥堵数据
    clickHandle(item) {
      // item.check = !item.check;
      const sleep = (time) => {
        return new Promise((resolve) => setTimeout(resolve, time));
      };
      sleep(600).then(() => {
        this.routeList
          .filter((c) => c.congestionId !== item.congestionId)
          .forEach((item) => {
            item.check = false;
          });
        this.$set(item, 'check', !item.check);
        console.log(item);
        if (item.check) {
          const listLinkInfoState = item.listLinkInfoState;
          let lineArr = [];
          listLinkInfoState.forEach((item) => {
            let arr = item['lngLats'].split(';');
            let lngArr = [];
            arr.forEach((ar) => {
              let lng = ar.split(',');
              lngArr.push(lng);
            });
            // lineArr = lineArr.concat(lngArr);
            lineArr.push(lngArr);
          });
          let center = item.centerPointWKT
            .replace(/^POINT\((.*)\)/g, '$1')
            .split(' ');
          this.amapIns && this.amapIns.drawMulLine(lineArr, center);
        } else {
          this.amapIns.removeDrawMulMarker();
        }
      });
    },
    //拥堵距离
    getDistance(distance) {
      return parseFloat(distance / 1000).toFixed(2);
    },
    //拥堵速度
    getSpeed(speed) {
      return parseFloat(speed / 1000).toFixed(2);
    },
    //持续时间
    getDurationMin(duration) {
      return duration > 60
        ? parseFloat(duration / 60).toFixed(1) + '小时'
        : duration + '分钟';
    },
    //拥堵报出时间
    getBlockStartTime(initTime) {
      const now = +Date.now();
      const timeObj = {
        天: now + 24 * 60 * 60 * 1000,
        年: now + 24 * 60 * 60 * 1000 * 365,
      };
      if (initTime < timeObj['天']) {
        // return moment(initTime).format('HH:ss');
        return this.timestampToTime(initTime);
      } else if (initTime > timeObj['天'] && initTime < timeObj['年']) {
        return moment(initTime).format('MM-DD');
      } else {
        return moment(initTime).format('YYYY-MM-DD');
      }
    },
    timestampToTime(timestamp) {
      let date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      /*  let Y = date.getFullYear() + '-';
    let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
    let D = date.getDate() + ' '; */
      let h = this.padd(date.getHours()) + ':';
      let m = this.padd(date.getMinutes());
      /*  let s = date.getSeconds(); */
      // return Y+M+D+h+m+s;
      return h + m;
    },
    padd(n) {
      return Number(n)<10? '0'+n:n;
    },
    //道路等级
    handleRoutRankChange(value) {
      this.currentRoadType = value;
      this._getCongestedSection();
    },
    //道路长度
    handleLengthChange(value) {
      this.currentDistance = value;
      this.pageNum = 1;
      this._getCongestedSection();
    },
    //拥堵持续时间
    handleDurationChange(value) {
      this.currentDuration = value;
      this.pageNum = 1;
      this._getCongestedSection();
    },
  },
  destroyed() {
    this.apiTimer && clearTimeout(this.apiTimer);
    if (this.$refs.scrollWrapper) {
      this.$refs.scrollWrapper.addEventListener('scroll', () => {
        this.timer && clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.scroll();
        }, 300);
      });
    }
  },
};
</script>

<style scoped lang="less">
.home-right {
  background: linear-gradient(
    0deg,
    rgba(15, 40, 61, 0.3),
    rgba(46, 86, 142, 0.3)
  );

  height: 100%;
  color: #fff;
}
.scroll {
  overflow: auto;
  height: calc(100% - 70px);
}
.block-route {
  height: calc(100% - 272px);
  .hd {
    background: linear-gradient(
      180deg,
      rgba(20, 51, 84, 0.3),
      rgba(70, 121, 191, 0.6)
    );
    padding: 5px 10px;
  }
  .select-item {
    padding: 5px 0;
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
    &.current {
      background: rgb(23, 46, 96);
    }
    .select-item-fl {
      .select-item-block {
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        border-radius: 100%;
        &.rank3 {
          background: rgb(239, 122, 126);
        }
        &.rank4 {
          background: rgb(162, 38, 29);
        }
        &.rank1 {
          background: rgb(168, 168, 168);
        }
        &.rank2 {
          background: rgb(236, 140, 66);
        }
      }
    }
    .time {
      width: 40px;
      text-align: right;
    }
  }
}
.min-wd {
  min-width: 0;
}
.text-color {
  color: #91d1ff;
}
.title-color {
  color: #189eff;
}
.trend {
  display: block;
  width: 14px;
  height: 14px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.trend.trend2 {
  background-image: url(~@/assets/home/trend2.png);
}
.trend.trend3 {
  background-image: url(~@/assets/home/trend3.png);
}
.trend.trend4 {
  background-image: url(~@/assets/home/trend4.png);
}
.i-s {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  vertical-align: -2px;
  margin-right: 5px;
}
.i-speed {
  background-image: url(~@/assets/home/icon-speed.svg);
}
.i-duration {
  background-image: url(~@/assets/home/icon-duration.svg);
}
.i-block {
  background-image: url(~@/assets/home/icon-block.svg);
}
</style>
