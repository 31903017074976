<template>
  <div class="fix fix-wrap" v-if="visible">
    <a-icon type="close-square" class="close f20" @click="closeHandle" />
    <div class="content absolute">
      <home-main :isException="isException" :data="data"></home-main>
    </div>
  </div>
</template>

<script>
import HomeMain from './HomeMain';
// import QFMap from '@/components/QFMap';
export default {
  name: 'ExceptionMap',
  props: {},
  components: {
    HomeMain,
    // QFMap,
  },
  data() {
    return {
      visible: false,
      isException: true,
      data: null,
    };
  },
  computed: {},
  watch: {},
  methods: {
    closeHandle() {
      this.visible = false;
      this.isException = false;
      this.data = null;
    },
    showHandle(data) {
      this.visible = true;
      this.isException = true;
      this.data = data;
    },
  },
};
</script>

<style scoped>
.fix-wrap {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 1);
  z-index: 9999;
}
.close {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 99999;
}
.content {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
</style>
