<template>
  <div class="full-height">
    <!--style="height:calc(100% - 272px);"-->
    <div v-hasPermiss="'10921016'" class="ht-bottom-style">
      <template>
        <!--  v-if="$g.isDepart" -->
          <div class="btn-group m-b-md">
            <div class="flex justify-between btn-group-select">
              <div class="flex flex-one">
                <span
                  :class="[
                    { current: activeMenu === 0 },
                    'pointer text-center f12',
                  ]"
                  @click="tabClick(0)"
                  >拥堵路段
                  <!-- <span class="f12"
                  >({{ blockList ? blockList.length : 0 }})</span
                > --></span
                >
                <span
                  v-if="$g.isDepart"
                  :class="[
                    { current: activeMenu === 2 },
                    'pointer text-center f12',
                  ]"
                  @click="tabClick(2)"
                  >拥堵收费站</span
                >
                <span
                  v-if="$g.isDepart"
                  :class="[
                    { current: activeMenu === 4 },
                    'pointer text-center f12',
                  ]"
                  @click="tabClick(4)"
                  >路段流量<br />TOP10</span
                >
                <span
                  v-if="$g.isDepart"
                  :class="[
                    { current: activeMenu === 3 },
                    'pointer text-center f12',
                  ]"
                  @click="tabClick(3)"
                  >异常率<br />TOP10</span
                >
                <span
                  v-if="$g.isAlarm"
                  :class="[
                    { current: activeMenu === 1 },
                    'pointer text-center f12',
                  ]"
                  @click="tabClick(1)"
                  >事件报警</span
                >
              </div>
              
              <!-- v-if="activeMenu === 1" -->
              <div v-if="false">
                <a-select v-model="text" style="width: 80px" @change="handleChange">
                  <a-select-option :value="1">
                    今日
                  </a-select-option>
                  <a-select-option :value="7">
                    近七天
                  </a-select-option>
                  <a-select-option :value="30">
                    近30天
                  </a-select-option>
                </a-select>
              </div>
            </div>
          </div>
          <div class="flex items-center m-b-sm" v-if="false">
            <span class="m-r-sm text-lightblue">拥堵路段</span>
            <a-dropdown :trigger="['click']">
          <span class="ant-dropdown-link">
            {{ currentRouteType }} <a-icon type="down" />
          </span>
          <a-menu slot="overlay">
            <a-menu-item
              v-for="item in routeType"
              :key="item.id"
              @click="handleRouteType(item)"
            >
              {{ item.text }}
            </a-menu-item>
          </a-menu>
        </a-dropdown>
          </div>
      </template>
      <template>
        <template
          v-if="
            '0,2'.includes(activeMenu) &&
              !hasPermission(
                {
                  0: 1092101410,
                  2: 1092101411,
                }[activeMenu]
              )
          "
        >
          <div
            class="full-width full-height justify-center items-center text-lightblue"
          >
            对不起，您还没有当前操作权限，请联系管理员申请！
          </div>
        </template>
        <template v-else>
          <list-congest
            v-if="activeMenu === 0"
            :type="type"
            :list="blockList"
            :column="column"
          />
          <template v-if="$g.isDepart">
            <list-congest
              v-if="activeMenu === 2 || activeMenu === 3 || activeMenu === 4"
              :list="blockStationList"
              :column="column"
              :type="type"
              :callback="showMap"
            />
          </template>
          <template v-if="$g.isAlarm">
            <list-alarm :msg="text" v-show="activeMenu === 1" />
          </template>
        </template>
      </template>
      <exception-map ref="exceptionMap" />
    </div>
  </div>
</template>
<script>
import ListCongest from './LIstCongest';
import ExceptionMap from './ExceptionMap';
import { mapActions, mapState } from 'vuex';
import ListAlarm from './ListAlarm.vue';
import { getBlockList, getAbormalList } from '@/tools';
import { CODE_OK } from '@/request/config_code';
export default {
  name: 'HomeRightBottom',
  data() {
    return {
      activeMenu: 0,
      text: 1,
      blockStationList: [],
      column: [
        {
          id: 0,
          name: '排序',
          className: 'wd1',
        },
        {
          id: 1,
          name: '拥堵路段',
          className: 'flex-one',
        },
        {
          id: 2,
          name: '拥堵长度',
          className: 'wd2',
        },
      ],
      type: 'road',
      permission: false,
      currentRouteType:'道路等级',
      routeType:[
        { 
          id:0,
          text:'道路等级'
        },
        { 
          id:1,
          text:'拥堵时长'
        },
        { 
          id:2,
          text:'拥堵距离'
        }
      ]
    };
  },
  components: {
    ListCongest,
    ListAlarm,
    ExceptionMap,
  },
  computed: {
    ...mapState({
      blockList: (state) => state.home.blockList,
    }),
    ht() {
      let heights = 220 + 90 + 'px';
      return `calc(100% - ${heights})`;
    },
  },
  watch: {},
  mounted() {
    this.getPermistion('1092101610');
    this.getAllRoadLineBlockList();
    /*  if (this.$g.isDepart) {
      this._getBlockStationList();
    } */
  },
  methods: {
    //拥堵等级
    handleRouteType(item) {
      this.currentRouteType = item.text
    },
    getPermistion(value) {
      return (this.permission = this.hasPermission(value));
    },
    handleChange(value) {
      this.text = value;
    },
    showMap(data) {
      (this.type === 'exception' || this.type === 'RoadTraffic') &&
        this.$refs.exceptionMap.showHandle(data);
    },
    tabClick(index) {
      this.activeMenu = index;
      this.text = 1;
      if (this.activeMenu === 0) {
        this.getAllRoadLineBlockList();
        this.column[1].name = '拥堵路段';
        this.column[2].name = '拥堵长度';
        this.type = 'road';
      } else if (this.activeMenu === 2) {
        this.getPermistion('1092101611');
        this._getBlockStationList();
        this.column[1].name = '拥堵收费站';
        this.column[2].name = '拥堵长度';
        this.type = 'station';
      } else if (this.activeMenu === 3) {
        // this.getPermistion('1092101610');
        this.permission = true;
        this._getAbnormal();
        this.column[1].name = '路段单位';
        this.column[2].name = '异常率';
        this.type = 'exception';
      } else if (this.activeMenu === 4) {
        this.permission = true;
        // this.getPermistion('1092101610');
        this.column[1].name = '路段';
        (this.column[2].name = '车流量'), (this.type = 'RoadTraffic');
        this._getTrafficVolume();
      } else if (this.activeMenu === 1) {
        this.getPermistion('1092101613');
      }
    },
    _getBlockStationList() {
      this.blockStationList = [];
      this.$api.aiCenterY.getBlockStationList().then((res) => {
        if (res.code === 200) {
          // console.error(res.data);
          this.blockStationList = res.data
            ? res.data.map((item) => getBlockList(item))
            : [];
        }
      });
    },
    _getAbnormal() {
      this.blockStationList = [];
      this.$api.aiCenterY.getAbnormal().then((res) => {
        if (res.code === CODE_OK) {
          // console.error(res.data);
          this.blockStationList = res.data
            ? res.data.map((item) => getAbormalList(item))
            : [];
        }
      });
    },
    _getTrafficVolume() {
      this.blockStationList = [];
      this.$api.aiCenterY.getTrafficVolume().then((res) => {
        if (res.code === CODE_OK) {
          this.blockStationList = res.data.map((item, index) => {
            return {
              id: index + 1,
              blockRoadName: item.roadName,
              blockRoadLength: item.trafficVolumeInfo,
              regionCode: item.regionCode,
              roadCode: item.roadCode,
            };
          });
        }
      });
    },
    ...mapActions(['getAllRoadLineBlockList']),
  },
};
</script>

<style scoped>
.btn-group-select {
  margin-bottom: 1px;
}
.btn-group-select span {
  flex: 1;
  line-height: 1;
  height: 30px;
  margin-right: 1px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.btn-group-select span:last-child {
  margin-right: 0;
}
.ht-bottom-style {
  height: calc(100% - 272px);
}
</style>
