<template>
  <div class="collect-com full-height">
    <div class="p-sm full-height">
      <div class="flex items-center justify-between m-b-sm title-con p-h-sm">
        <span
          class="pointer text-lightblue flex items-center"
          @click="addFavoritHandle"
          ><!-- <a-icon type="plus-square" class="m-r-xs" /> --><img
            src="../assets/resource/collect-add.png"
            width="20"
            class="m-r-xs"
          />新增收藏夹</span
        >
        <span
          class="flex flex-end text-lightblue items-center pointer"
          v-show="ope"
          @click="newTurnHandle"
        >
          <a-icon type="plus-circle" class="m-r-xs" />收藏轮巡
        </span>
      </div>

      <div class="folder-wrapper">
        <div
          class="folder m-b-sm pointer"
          v-for="folder in homeFolderCamera"
          :key="folder.folderId"
        >
          <div
            class="folder-hd flex justify-between items-center text-lightblue f15 m-b-sm"
            @click="expandFolder(folder)"
          >
            <div class="text-white flex ">
               
              <span class="weight flex items-center" :title="folder.folderName"
                ><!-- <a-icon type="folder" class="m-r-xs" /> --><img
                  src="../assets/resource/collect-folder.png"
                  width="20"
                  class="m-r-xs"
                />{{ subName(folder.folderName)
                }}<!-- {{
                  folder.folderName
                }} --></span
              >({{ folder.cameraNumber }})
            </div>
          <div class="flex items-center">
            <i
              title="收藏列表投屏"
              class="icon-project"
              :class="{'m-r-sm':!folder.whetherDefaultFolder}"
              @click="projectHandle(folder,$event)"
            ></i>
            <span v-show="!folder.whetherDefaultFolder">
              <a-icon
                type="edit"
                title="修改"
                class="pointer m-r-sm"
                @click.stop.prevent="handleEditFavorit(folder)"
              />
              <a-icon
                type="rest"
                title="删除"
                class="pointer"
                @click.stop.prevent="
                  deleteFolder(folder.folderId, folder.folderName)
                "
              />
            </span>
          </div>
          </div>
          <template
            v-if="folder.show && folder.cameraList && folder.cameraList.length"
          >
            <div
              class="flex items-center m-b-sm p-w-sm"
              v-for="item in folder.cameraList"
              :key="item.cameraId"
              :title="item.cameraNames"
            >
              <span
                :class="[
                  'icon-style  m-r-xs flex-s',
                  {
                    online: item.cameraStatus === 1,
                    offline: item.cameraStatus === 0,
                    error: item.cameraStatus === 2,
                    yunOffline: item.cameraStatus === 0 && $g.treeDefinate,
                  },
                ]"
              ></span>
              <!--ellipsis-->
              <span
                class="flex-one  pointer ellipsis"
                :class="{'text-lightgray line-througth':item.deleteStatus === 1}"
                style="white-space:nowrap"
                @click.stop.prevent="selectShot(item)"
                ><!-- {{ item.cameraName }}
                {{ item.derection | getDirectionArrow }} -->{{
                  item.cameraNames
                }}</span
              >
              <i
                class="icon-collect current m-l-sm pointer"
                title="取消收藏"
                v-if="ope"
                @click.stop.prevent="cancelCollectHandle(item, folder.folderId)"
              />
            </div>
          </template>
        </div>
      </div>
    </div>

    <new-turn
      :visible="turnVisible"
      selectAllMode="collect"
      @closeDialog="closeDialogHandle"
    />

    <favorit-toggle
      :visible="favoritVisible"
      :edit="isEdit"
      :folderId="folderId"
      :folderName="folderName"
      @close="closeFavorit"
      @sure="sureFavorit"
    />
    <!--收藏夹投屏-->
    <camera-group-setting
      ref="cameraGroupSetting"
      :visible="visibleGroupSetting"
      @close="closeGroupSettingHandle"
      @currentScreen="currentScreenHandle"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import newTurn from '@/components/TurnDialog';
import FavoritToggle from '@/components/FavoritToggle';
import CameraGroupSetting from './CameraGroupSetting';
import { DIRECTOINARROW } from '@/tools';
import { setSessionStorage } from '@/tools';
import { getStorage, setStorage } from '@/tools';
import { CODE_OK } from '@/request/config_code';
// import {wgs84_to_gcj02} from '@/tools/wgs84_to_gcj02'
export default {
  name: 'CollectCom',
  props: {
    ope: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      turnVisible: false,
      favoritVisible: false,
      isEdit: false,
      folderId: '',
      folderName: '',
      visibleGroupSetting:false,
      folderProId:''
    };
  },
  computed: {
    ...mapState({
      cameraCollectList: (state) => state.collect.cameraCollectList,
      screenShotSearchParams: (state) => state.resource.screenShotSearchParams,
      amapIns: (state) => state.home.amapIns,
      homeFolderCamera: (state) => state.home.homeFolderCamera,
    }),
  },
  watch: {},
  components: {
    newTurn,
    FavoritToggle,
    CameraGroupSetting
  },
  mounted() {
    // this.getCollectList();
    this.getHomeDefaultCamera();
  },
  methods: {
    ...mapMutations([
      'setCameraTab',
      'setScreenShotSearchParams',
      'setSelectCameraList',
      'setSessionCurrentCamera',
    ]),
    ...mapActions([
      'getCollectList',
      'deleteFolderCollectItem',
      'getScreenShotInfo',
      'getHomeDefaultCamera',
      'getHomeFolderCamera',
      'deleteFolderAction',
    ]),
    subName(name) {
      return name.length > 10 ? name.slice(0, 10) + '...' : name;
    },
    cancelCollectHandle(item, folderId) {
      if (!this.hasPermission(109210101014)) {
        this.permissionWarning();
        return;
      }

      this.$confirm({
        title: '提示',
        content: '确认取消收藏该路摄像机?',
        onOk: () => {
          let params = {
            cameraId: item.cameraId,
            folderId,
          };
          let obj = {
            params,
            instructionsDel: {
              module: '重点关注',
              page: '收藏列表',
              feature: '取消收藏',
              description: `取消收藏: ${item.cameraName}`,
            },
          };
          this.deleteFolderCollectItem(obj).then((data) => {
            if (data.code === 200) {
              item.folderId = folderId;
              this.$set(item, 'hasFavorited', false);
              setSessionStorage('currentCamera', item);
              this.setSessionCurrentCamera(item);
              this.$message.success('取消收藏成功！');
            }
          });
        },
        onCancel: () => {},
      });
    },
    getTitle(item) {
      return `桩号:${item.kmHmPile}.方向:${
        DIRECTOINARROW[item.derection]
      },兴趣点名称:${item.poiName}`;
    },
    newTurnHandle() {
      if (!this.hasPermission(109210101015)) {
        this.permissionWarning();
        return;
      }

      this.turnVisible = true;
      this.setCameraTab('collect');
      this.setSelectCameraList(this.cameraCollectList);
    },
    closeDialogHandle() {
      this.turnVisible = false;
    },
    selectShot(item) {
      if (this.ope) {
        // console.log(item);
        if(item.deleteStatus === 1) {
          this.$message.error('摄像机已删除，无法调阅该摄像机!');
          return;
        }
        if (item.otherOrganizationId === 1) {
          this.$message.error('暂无观看权限！');
          return;
        }
        let cameraInfo = item;
        // cameraInfo.lonlat = [cameraInfo.longitude, cameraInfo.latitude];fix以前
        cameraInfo.lonlat =[cameraInfo.longitude, cameraInfo.latitude];
        // console.error('tt',wgs84_to_gcj02(cameraInfo.longitude, cameraInfo.latitude))
        /*   if (cameraInfo.cameraStatus == 1) { */
        this.amapIns.cameraInfoWindowShow({
          data: {
            // position: [cameraInfo.longitude, cameraInfo.latitude],fix以前
            position:cameraInfo.lonlat,
            onlineStatus: cameraInfo.status,
            cameraName: cameraInfo.cameraName,
            cameraId: cameraInfo.cameraId,
            cameraNum: cameraInfo.cameraNum,
            organizationId: cameraInfo.organizationId,
            regionCode: cameraInfo.regionCode,
            roadId: cameraInfo.roadId,
            kmPile: cameraInfo.kmPile,
            hmPile: cameraInfo.hmPile,
            classify: cameraInfo.classify,
            cameraType: cameraInfo.cameraType,
            zoomlevel: cameraInfo.zoomlevel,
            source: cameraInfo.source,
            khPile: cameraInfo.kmHmPile,
            collectFlag: 1,
            /* resolutionValue: cameraInfo.organizationId.startsWith('9901004075')
              ? 1
              : 0, */
            resolutionValue: cameraInfo.transcodingId === '1354354698693513216' ? 1 : 0,
            // lonlat: [cameraInfo.longitude, cameraInfo.latitude],fix以前
            lonlat:cameraInfo.lonlat,
          },
        });
        if (this.$g.isDepart) {
          this.amapIns.addActCameraIcon(cameraInfo);
        } else {
          this.amapIns.addVideoMarker(cameraInfo);
        }
        /*   } else {
          this.$message.error('该摄像机不在线，不能拉流！');
        } */
        return;
      }
      let data = {
        ...this.screenShotSearchParams,
        ...{ cameraId: item.cameraId },
      };
      this.setScreenShotSearchParams(data);
      this.getScreenShotInfo(data);
    },
    addFavoritHandle() {
      if (!this.hasPermission(109210101010)) {
        this.permissionWarning();
        return;
      }

      this.favoritVisible = true;
      this.isEdit = false;
      this.folderId = '';
      this.folderName = '';
    },
    handleEditFavorit(item) {
      if (!this.hasPermission(109210101011)) {
        this.permissionWarning();
        return;
      }

      this.favoritVisible = true;
      this.isEdit = true;

      this.folderId = item.folderId;
      this.folderName = item.folderName;
      console.log(item, this.folderId, this.folderName);
    },
    closeFavorit() {
      this.favoritVisible = false;
    },
    sureFavorit() {
      this.favoritVisible = false;
      this.$message.success(`${this.isEdit ? '编辑' : '新增'}收藏文件夹成功！`);
    },
    expandFolder(folder) {
      if (!folder.show) {
        let data = {
          folder: folder.folderId,
        };
        this.getHomeFolderCamera(data).then(() => {
          folder.show = !folder.show;
        });
        return;
      }
      folder.show = !folder.show;
    },
    deleteFolder(folderId, folderName) {
      if (!this.hasPermission(109210101012)) {
        this.permissionWarning();
        return;
      }

      let data = {
        folderId,
        whetherDefaultFolder: 0,
        folderName,
      };
      this.$confirm({
        title: '提示',
        content: '确认删除该收藏文件夹吗?',
        onOk: () => {
          this.deleteFolderAction(data);
        },
        onCancel: () => {},
      });
    },
    //投屏
    projectHandle(folder,e) {
       
      e.stopPropagation();
      let cameraInfoStore = getStorage('collectCamera');
      if (cameraInfoStore && JSON.stringify(cameraInfoStore) != '{}') {
        this.$message.error('每个收藏夹摄像机只能同时投屏一次！');
        return;
      }
      this.visibleGroupSetting = true;
      this.folderProId = folder.folderId;
    },
    currentScreenHandle(curScree) {
      let currentScreen = curScree.mode;
      let tit = curScree.litTit;
      let interval = curScree.interval;
      // this.visibleGroupSetting = false;
      // console.log(currentScreen, tit, this.projectDataList);
      let { href } = this.$router.resolve({
        path: `/${currentScreen}-window${tit}`,
      });
      let paramsObj = {
        folder: this.folderProId,
      };
      this.$refs['cameraGroupSetting'].setLoading(true);
      this.getHomeFolderCamera(paramsObj).then(res => {
        if (res.code === CODE_OK) {
          this.$refs['cameraGroupSetting'].setLoading(false);
          this.projectDataList = res.data.filter(item=>item.deleteStatus !== 1).map(item=>{
            item.cameraName = item.cameraNames;
            return item;
          });
          this.visibleGroupSetting = false;
          window.open(`${href}?isCollect=1`, '_blank');
          setStorage('collectCamera', {interval,arr:this.projectDataList});
        }
      });
    },
    closeGroupSettingHandle() {
      this.visibleGroupSetting = false;
    },
  },
};
</script>

<style lang="less" scoped>
.collect-com {
  .folder-wrapper {
    height: calc(100% - 56px);
    margin-right: -0.5rem;
    overflow-y: overlay;
    padding-right: 0.5rem;
  }
}
</style>
<style scoped>
.title-con {
  border-bottom: 1px dashed rgba(40, 69, 106, 1);
}
/* .collect-com ::-webkit-scrollbar {
  display: none;
} */
.icon-project {
  background: url('../assets/resource/icon-project.png') no-repeat;
  background-size: 100% 100%;
  width: 16px;
  height: 13px;
}
.line-througth{
  text-decoration: line-through;
}
</style>
