<template>
  <div class="on-con list-con relative" style="height:calc(100% - 50px);">
    <span class="t-lf cor absolute"></span>
    <span class="t-rf cor absolute"></span>
    <span class="b-lf cor absolute"></span>
    <span class="b-rf cor absolute"></span>
    <div class="list-center full-height">
      <div class="on-con-head flex f12 m-b">
        <span
          :class="[
            'con-bg text-center text-lightblue m-r-xs p-w-xs',
            item.className,
          ]"
          v-for="item in column"
          :key="item.id"
          >{{ item.name }}</span
        >
      </div>
      <vue-scroll :ops="$root.scrollOpsY" style="height: calc(100% - 21px);">
        <div
          class="flex items-center justify-center full-width full-height"
          v-if="!list.length"
        >
          <a-spin></a-spin>
        </div>
        <template v-if="list && list.length">
          <div
            class="on-con-body flex text-lightblue m-b-sm pointer"
            v-for="(item, index) in list"
            :key="index"
            @click="drawLine(item)"
          >
            <span class="wd1 text-center">{{ index + 1 }}.</span>
            <span
              class="flex-one ellipsis p-w-sm"
              :title="item.blockRoadName"
              >{{ item.blockRoadName }}</span
            >
            <span
              class="wd2 text-center"
              v-if="type !== 'exception' && type !== 'RoadTraffic'"
              >{{ item.blockRoadLength }}km</span
            >
            <span
              :class="[`wd2 text-center ${getColorClass(item.percent)}`]"
              v-else-if="type === 'exception'"
              >{{ item.percent }}</span
            >
            <span class="wd4 text-center" v-else-if="type === 'RoadTraffic'">{{
              item.blockRoadLength
            }}</span>
            <!-- <span
            class="wd3 text-center pointer"
            v-show="text === '摄像机'"
            
            ><img src="../../../assets/home/video.png" width="12"
          /></span> -->
          </div>
        </template>
      </vue-scroll>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'ListCongest',
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    text: {
      type: String,
      default: '',
    },
    column: {
      type: Array,
      default() {
        return [];
      },
    },
    type: {
      type: String,
      default: '',
    },
    callback: {
      type: Function,
      default() {
        return () => {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      amapIns: (state) => state.home.amapIns,
    }),
    /* listFormat() {
      return [{ blockRoadName: 'aa', blockRoadLength: 300 }];
    }, */
  },
  watch: {},
  methods: {
    // eslint-disable-next-line no-unused-vars
    drawLine(item) {
      /* console.error(item); */
      /* var lineArr = new Array();
      var lineArr2 = new Array();
      var lineStr =
        '118.626495,32.05714;118.626785,32.057396;118.626892,32.057491;118.62735,32.057919;118.627472,32.058029;118.627556,32.058102;118.627808,32.058334;118.628204,32.058693;118.62896,32.059357;118.629112,32.059509;118.629295,32.059666;118.629402,32.05975;118.629501,32.05983;118.629837,32.060143;118.629837,32.060204;118.630028,32.060368;118.630142,32.060471;118.630363,32.060677;118.630371,32.060688;118.630737,32.061008;118.630852,32.061108;118.630989,32.061226;118.631119,32.061359;118.631271,32.061485;118.631645,32.061813;118.63192,32.062057;118.63237,32.062454;118.632515,32.062572;118.632607,32.062645;118.632896,32.062885;118.632988,32.062965;118.633209,32.063141;118.633781,32.06358;118.634094,32.063835;118.634216,32.063919;118.634514,32.064144;118.634811,32.064362;118.634811,32.064362;118.63501,32.064419;118.635162,32.064423;118.6353,32.064396;118.635414,32.064358;118.635788,32.064137;118.635788,32.064137;118.635651,32.063931;118.635597,32.063915;118.635201,32.064106';
      for (var i = 0; i < lineStr.split(';').length; i++) {
        lineArr[i] = lineStr.split(';')[i];
        lineArr2[i] = new Array();
        for (var j = 0; j < lineArr[i].split(',').length; j++) {
          lineArr2[i][j] = lineArr[i].split(',')[j];
        }
      }
      console.error('-----', lineArr2); */
      // console.log('haha', item);
      if (this.type === 'RoadTraffic') {
        this.callback &&
          this.callback({
            type: 'RoadTraffic',
            regionCode: item.regionCode,
            roadCode: item.roadCode,
          });
        return;
      }
      if (this.type === 'exception') {
        this.callback &&
          this.callback({
            type: 'exception',
            organizationId: item.organizationId,
          });
        return;
      }
      item.check = !item.check;
      if (item.check) {
        if (this.type === 'road') {
          if (!this.hasPermission(109210141010)) {
            this.permissionWarning();
            return;
          }

          let lineArr2 = JSON.parse(item.pline).flat(1);
          let center = lineArr2[0];
          this.amapIns.drawLine(lineArr2, center);
        } else if (this.type === 'station') {
          if (!this.hasPermission(109210141110)) {
            this.permissionWarning();
            return;
          }

          this.amapIns.setPoint(item.pline);
        }
      } else {
        this.amapIns.removeDrawMarker();
      }
    },
    getColorClass(percent) {
      return parseInt(percent) > 60 ? 'text-red' : 'text-orange';
    },
  },
};
</script>

<style scoped>
.list-con {
  padding: 18px 0;
}
.list-center .on-con-head {
  line-height: 16px;
}
.list-center .con-bg {
  background: rgba(46, 86, 142, 0.3);
}
.wd1 {
  width: 13%;
}
.wd2 {
  width: 20%;
}
.wd3 {
  width: 18%;
}
.wd4 {
  width: 30%;
}
</style>
