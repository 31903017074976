<template>
  <div>
    <a-modal
      :title="title"
      :visible="visible"
      width="24%"
      :centered="true"
      :destroyOnClose="true"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
      @ok="handleOk"
    >
      <a-input placeholder="请输入收藏夹名称" v-model="text" />
      <span class="text-red block m-t-xs" v-show="validError"
        >支持50个字符，非空格非空输入</span
      >
    </a-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'FavoritToggle',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    folderId: {
      type: String,
      default: '',
    },
    folderName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      confirmLoading: false,
      text: '',
    };
  },
  created() {},
  computed: {
    title() {
      return `${this.edit ? '修改' : '新增'}文件夹`;
    },
    validError() {
      let reg = /\s/g;
      return reg.test(this.text) || (this.text.length > 50 || this.text.length <= 0) ? true : false;
    },
  },
  watch: {
    visible(nv,ov) {
      console.log('haha',nv,ov)
      if (nv && nv !== ov) {
        if(this.edit){
           this.text = this.folderName;
        }
       
      }
    },
  },
  methods: {
    handleCancel() {
      this.text = '';
      this.$emit('close');
    },
    handleOk() {
      if (this.validError) {
        /* this.$message.error('收藏文件夹名需要支持20个字符，非空格输入'); */
        return;
      }
      let data = {
        folderName: this.text,
      };
      if (this.edit) {
        data.folderId = this.folderId;
        data.folderNameBefore = this.folderName;
      }
      this.confirmLoading = true;
      this.createFolderAction(data).then(() => {
        this.confirmLoading = false;
        this.text = '';
        this.$emit('sure');
      });
    },
    ...mapActions(['createFolderAction']),
  },
};
</script>

<style scoped></style>
