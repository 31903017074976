<template>
  <div class="flex img-list justify-around items-center">
    <i
      :class="[
        'pointer icon-img',
        item.name,
        { current: item.value === classify },
      ]"
      v-for="item in list"
      :key="item.id"
      @click="selectHandle(item)"
    />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
export default {
  name: 'HomeLeftSubMenu',
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      // curIndex: 0,
    };
  },
  computed: {
    ...mapState({
      classify: (state) => state.home.classify,
    }),
  },
  watch: {},
  methods: {
    ...mapMutations(['setClassify']),
    selectHandle(item) {
      // this.curIndex = index;
      this.setClassify(item.value);
      this.$emit('selectSubTab');
    },
  },
};
</script>

<style scoped>
.img-list {
  height: 30px;
  background: rgba(16, 36, 69, 0.5);
  border: 1px transparent solid;
  border-image: linear-gradient(
      to right,
      rgba(16, 36, 69, 1),
      rgba(57, 109, 178, 1),
      rgba(16, 36, 69, 1)
    )
    1 0;
  width: 300px;
  margin: 15px 0 20px;
}
.img-list img {
  width: 22px;
  height: 22px;
}
.icon-img {
  width: 22px;
  height: 22px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.icon-sm-0 {
  background-image: url('../../../assets/home/icon-sm-0.png');
}
.icon-sm-1 {
  background-image: url('../../../assets/home/icon-sm-1.png');
}
.icon-sm-2 {
  background-image: url('../../../assets/home/icon-sm-2.png');
}
.icon-sm-3 {
  background-image: url('../../../assets/home/icon-sm-3.png');
}
.icon-sm-4 {
  background-image: url('../../../assets/home/icon-sm-4.png');
}
.icon-sm-5 {
  background-image: url('../../../assets/home/icon-sm-5.png');
}
.icon-sm-6 {
  background-image: url('../../../assets/home/icon-sm-6.png');
}
.icon-sm-7 {
  background-image: url('../../../assets/home/icon-sm-7.png');
}
.icon-sm-8 {
  background-image: url('../../../assets/home/icon-sm-8.png');
}
.icon-sm-0:hover,
.icon-sm-0.current {
  background-image: url('../../../assets/home/icon-sm-0-on.png');
}
.icon-sm-1:hover,
.icon-sm-1.current {
  background-image: url('../../../assets/home/icon-sm-1-on.png');
}
.icon-sm-2:hover,
.icon-sm-2.current {
  background-image: url('../../../assets/home/icon-sm-2-on.png');
}
.icon-sm-3:hover,
.icon-sm-3.current {
  background-image: url('../../../assets/home/icon-sm-3-on.png');
}
.icon-sm-4:hover,
.icon-sm-4.current {
  background-image: url('../../../assets/home/icon-sm-4-on.png');
}
.icon-sm-5:hover,
.icon-sm-5.current {
  background-image: url('../../../assets/home/icon-sm-5-on.png');
}
.icon-sm-6:hover,
.icon-sm-6.current {
  background-image: url('../../../assets/home/icon-sm-6-on.png');
}
.icon-sm-7:hover,
.icon-sm-7.current {
  background-image: url('../../../assets/home/icon-sm-7-on.png');
}
.icon-sm-8:hover,
.icon-sm-8.current {
  background-image: url('../../../assets/home/icon-sm-8-on.png');
}
</style>
