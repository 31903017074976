<template>
  <div>
    <a-modal
      :title="title"
      :visible="visible"
      width="24%"
      :centered="true"
      :destroyOnClose="true"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
      @ok="handleOk"
    >
      <a-input placeholder="请输入共享夹名称" v-model="text" />
      <div class="text-wrap">
        <span class="text-red block m-t-xs" v-show="validError"
          >支持50个字符，非空格非空输入</span
        >
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'SharingToggle',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    folderId: {
      type: String,
      default: '',
    },
    folderName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      confirmLoading: false,
      text: '',
    };
  },
  created() {},
  computed: {
    title() {
      return `${this.edit ? '修改' : '新增'}共享夹`;
    },
    validError() {
      let reg = /\s/g;
      return reg.test(this.text) || (this.text.length > 50 || this.text.length <= 0) ? true : false;
    },
  },
  watch: {
    visible(nv,ov) {
      if (nv && nv !== ov) {
        if(this.edit){
           this.text = this.folderName;
        }
       
      }
    },
  },
  methods: {
    handleCancel() {
      this.text = '';
      this.$emit('close');
    },
    handleOk() {
      if (this.validError) {
        /* this.$message.error('收藏文件夹名需要支持20个字符，非空格输入'); */
        return;
      }
      let data = {
        folderName: this.text,
      };
      
      this.confirmLoading = true;
      if (this.edit) {
        data.folderId = this.folderId;
        this.modifySharingFolder(data).then(() => {
            this.confirmLoading = false;
            this.$emit('editsure',data.folderName);
            this.text = '';
        });
      } else {
          this.addSharingFolder(data).then(() => {
            this.confirmLoading = false;
            this.$emit('sure');
            this.text = '';
          });
      }
      
    },
    ...mapActions(['addSharingFolder', 'modifySharingFolder']),
  },
};
</script>

<style scoped>
.text-wrap {
  height: 30px;
  line-height: 30px;
}
</style>
