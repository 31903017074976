<template>
  <div
    class="flex flex-wrap m-t-xs bd-s-b bd-s-l bd-s-r bd-s-t full-width"
    id="wrap"
  >
    <drop
      class="screen-camera-item"
      v-for="(vo, key) in size"
      :key="key"
      :class="[
        'screen-item bd-r  width-' + size,
        {
          'bd-b': isBorder(key),
          currentBorder: currentBorderIndex === key
        }
      ]"
      :style="{ height: ht }"
      @drop="addScreenCamera($event, key)"
      @click.native="setCurrentActive(key)"
    >
      <template v-if="cameraList[key]">
        <div class="screen-camera-delete">
          <a-icon
            title="关闭"
            type="close"
            @click="removeScreenCamera(key)"
          />
        </div>
        <div class="screen-camera-title" v-show="false">
          <span>{{ cameraList[key].cameraName }}</span>
        </div>
        <div class="screen-camera-image">
          <flv-player
            :ref="'flvPlay' + key"
            video-type="flv"
            :showMin="true"
            :control="false"
          ></flv-player>
        </div>
        <div class="video-type" v-show="false">
          <a-select
            v-model="cameraList[key].videoType"
            style="width: 70px"
            size="small"
            @change="
              value => handleVideoTypeChange(value, key)
            "
          >
            <a-select-option :value="0">
              标清
            </a-select-option>
            <a-select-option :value="1">
              高清
            </a-select-option>
          </a-select>
        </div>
        <div
          class="full-width full-height justify-center items-center absolute loading-spin"
          v-show="false"
        >
          <a-spin />
        </div>
      </template>
      <template v-else>
        <div class="screen-camera-image">
          <loading
            loading-status="empty"
            message="请拖入想监控的视频画面"
          ></loading>
        </div>
      </template>
    </drop>
  </div>
</template>

<script>
import flvPlayer from '@/components/module/camera/FlvPlayer'
import { Drop } from 'vue-drag-drop'
import { mapState, mapMutations } from 'vuex'
import Loading from '@/components/module/Loading'
import { setStorage, getStorage } from '@/tools'
import store from '@/store'
export default {
  name: 'SplitScreen',
  components: {
    flvPlayer,
    Drop,
    Loading
  },
  data() {
    return {
      getUrlLoading: false,
      cameraList: [],
      ht: 0,
      // videoType: 0,
      currentBorderIndex: 0
    }
  },
  props: {
    size: {
      type: Number,
      default: 4
    },
    winIndex: {
      type: Number,
      default: 0
    }
  },

  watch: {
    size(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.oldSize = oldValue
        // this.destroyVideo(oldValue);前端处理
        this.ht = this.getHeight() + 'px'
        this.totalHeight(this.getHeight())
      }
    }
  },

  computed: {
    ...mapState({
      needStopCamera: state => state.home.needStopCamera
    })
  },
  /*beforeUpdate(){
    if(this.baseList.length) this.cameraList = this.baseList;
  },*/

  destroyed() {
    //销毁
    console.log('销毁')
    this.destroyVideoCom(false)
  },

  mounted() {
    /*this.$root.$on("removeEventMarker", () => {
      if(!this.chosedMark){
        return false;
      }
      if(this.cameraMarkVisable){
        this.backMark.show();
        return false;
      }
      //console.info(this.chosedMark);
      this.chosedMark.show();
      this.removeMarker(this.backMark);

      this.$refs.dragMarkerGhost.$el.style.top = "-50px";
      this.$refs.dragMarkerGhost.$el.style.left = "-50px";
    });*/
    this.ht = this.getHeight() + 'px'
    this.totalHeight(this.getHeight())
  },

  methods: {
    // ...mapActions(['getCameraPlayUrl', 'setCameraVisitRecords']),
    ...mapMutations([
      'setMaxHeight',
      'setProjectionVideoList'
    ]),
    setActiveBorder(currentBorderIndex) {
      this.currentBorderIndex = currentBorderIndex
      this.$emit(
        'handleActiveIndex',
        this.currentBorderIndex
      )
    },
    setCurrentActive(key) {
      this.currentBorderIndex = this.$parent.currentActiveIndex = key
      this.$emit(
        'handleActiveIndex',
        this.currentBorderIndex
      )
    },
    totalHeight(ht) {
      switch (this.size) {
        case 1:
          this.setMaxHeight(ht * 1)
          break
        case 4:
        case 6:
          this.setMaxHeight(ht * 2)
          break
        case 9:
        case 12:
          this.setMaxHeight(ht * 3)
          break
        case 16:
          this.setMaxHeight(ht * 4)
          break
      }
    },
    handleVideoTypeChange(value, index) {
      // console.log(value, index);
      this.$nextTick(() => {
        let idx = index,
          obj = this.$refs['flvPlay' + idx][0]
        let cameraInfo = this.cameraList[idx]
        cameraInfo.videoType = value
        // cameraInfo.loading = true;
        this.$set(this.cameraList, idx, cameraInfo)
        this.cameraPlay(obj, cameraInfo)
      })
    },
    getHeight() {
      let dom = document.querySelector('#wrap')

      let wd = dom.getBoundingClientRect().width
      switch (this.size) {
        case 1:
          return this.calHeight(wd, 1)
        case 4:
          return this.calHeight(wd, 2)
        case 6:
        case 9:
          return this.calHeight(wd, 3)
        case 12:
        case 16:
          return this.calHeight(wd, 4)
      }
    },
    calHeight(wd, size) {
      return ((wd / size) * 9) / 16
    },
    saveCameraList(list) {
      let cameraList = getStorage('cameraList')
      cameraList[this.winIndex] = list
      setStorage('cameraList', cameraList)
    },
    isBorder(index) {
      if (this.size == 4) {
        if (index < this.size - 2) {
          return true
        }
      } else if (this.size == 6 || this.size == 9) {
        if (index < this.size - 3) {
          return true
        }
      } else if (this.size == 12 || this.size == 16) {
        if (index < this.size - 4) {
          return true
        }
      }
    },
    cameraDelete(key) {
      //console.info("cameraDelete", key);
      this.$emit('itemDelete', key)
    },
    /**
     * isNoProject:判断是否是一键投屏进入的
     */
    setCameraList(list, isNoProject = true) {
      if (!list || !list.length) {
        this.cameraList.forEach((vo, idx) => {
          this.removeScreenCamera(idx)
        })
        this.cameraList = []
        return false
      }
      //多次切换视频流不播问题修复
      if (this.cameraList.length > 0 && isNoProject) {
        this.cameraList.forEach((vo, idx) => {
          this.removeScreenCamera(idx, true)
        })
      }
      // console.log('list', list);
      this.cameraList = []
      //取消请求
      store.commit('clear_cancel')
      list.forEach((vo, idx) => {
        this.addScreenCamera(vo, idx)
      })
    },
    addScreenCamera(cameraInfo, key) {
      //取消请求
      store.commit('clear_cancel')
      if (!cameraInfo) {
        /* if (this.$root.dragSelectCamera.cameraId) {
          cameraInfo = this.$root.dragSelectCamera;
        } else {
          return false;
        } */
        return false
      }

      //设置边框为具体索引
      let currentKey = key
      currentKey =
        currentKey >= this.size - 1 ? 0 : ++currentKey
      this.setCurrentActive(currentKey)

      this.$root.$emit('removeEventMarker', '')
      let cameraListFilter = this.cameraList.filter(
        item => !!item
      )
      console.log('dad', cameraListFilter.length, this.size)
      if (cameraListFilter.length > this.size) {
        this.$message.info('超过分屏数量限制！')
        return false
      }
      // console.log('视频', this.cameraList, cameraInfo);
      let isRepeat = false
      this.cameraList.forEach(item => {
        if (item.cameraNum === cameraInfo.cameraNum) {
          isRepeat = true
        }
      })
      if (isRepeat) {
        this.$message.info('视频已存在！')
        return false
      }
      /* if (this.cameraList.indexOf(cameraInfo) >= 0) {
        
      } fix前*/

      //this.screenSizeList.push(cameraInfo);
      cameraInfo.videoType = ''
      // cameraInfo.loading = false; //设置一个切换视频高清的loading
      this.cameraList[key] = cameraInfo
      // console.error('drop', cameraInfo, this.cameraList, key);
      this.saveCameraList(this.cameraList)
      //向上级传递数据，保存视屏
      this.$emit(
        'handleSplitCamera',
        JSON.parse(JSON.stringify(this.cameraList))
      )
      //更新一键投屏
      this.setProjectionVideoList(this.cameraList)
      //开始
      let extent = null
      extent = this.cameraList.length
      if (extent >= 1 && extent <= 16) {
        cameraInfo.playDomain =
          extent % 4 == 0
            ? extent / 4 - 1
            : Math.floor(extent / 4)
      } else {
        cameraInfo.playDomain = null
      }
      //结束
      this.$forceUpdate()
      // debugger;
      //this.cameraList.push(cameraInfo);
      //this.cameraList = [...[cameraInfo]];
      this.$nextTick(() => {
        setTimeout(() => {
          //  debugger;
          let idx = key, //this.cameraList.length - 1,
            obj = this.$refs['flvPlay' + idx][0]

          // console.info('addScreenCamera', obj);
          this.cameraPlay(obj, cameraInfo)
        }, 500)
      })

      /*setTimeout(() => {
        let idx = key, //this.cameraList.length - 1,
          obj = this.$refs["flvPlay"][idx];
        console.info("addScreenCamera", obj);
        this.cameraPlay(obj, cameraInfo);
      },500);*/

      // console.info('addScreenCamera list', this.cameraList);
    },

    destroyVideo(size) {
      for (let i = 0; i < size; i++) {
        this.removeScreenCamera(i, false)
      }
    },
    destroyVideoCom(flag = true) {
      for (let i = 0; i < this.cameraList.length; i++) {
        this.removeScreenCamera(i, flag)
      }
    },
    /* destroyInstanceNotDelData() {
       for (let i = 0; i < this.cameraList.length; i++) {
         let obj = this.$refs['flvPlay' + i] && this.$refs['flvPlay' + i][0];
         if(obj) {
            if (
              this.cameraList[i].videoType == 1 &&
              this.needStopCamera.cameraNum !== this.cameraList[i].cameraNum
            ) {
              this.stopStreamMulHandle(this.cameraList[i]);
            }
            obj.flv_destroy();
            delete this.cameraList[i];
         }
       }
    }, */
    removeScreenCamera(idx, flag = true) {
      //取消请求
      store.commit('clear_cancel')
      let obj =
        this.$refs['flvPlay' + idx] &&
        this.$refs['flvPlay' + idx][0]
      if (obj) {
        if (
          this.cameraList[idx].videoType == 1 &&
          this.needStopCamera.cameraNum !==
            this.cameraList[idx].cameraNum
        ) {
          this.stopStreamMulHandle(this.cameraList[idx])
        }
        obj.flv_destroy()
        delete this.cameraList[idx]

        // console.error('haha', this.cameraList);
      }
      /*   if (this.oldSize >= this.size) {
        if (idx === this.oldSize - 1) {
          this.cameraList = this.cameraList.filter((item) => !!item);
          this.saveCameraList(this.cameraList);
        }
      } else {
        if (idx === this.size - 1) {
          this.cameraList = this.cameraList.filter((item) => !!item);
          this.saveCameraList(this.cameraList);
        }
      } fix当前修改20210816
      //过滤cameraList
      let proCameraList = this.cameraList.filter((item) => !!item);*/

      //更新一键投屏
      this.saveCameraList(this.cameraList)
      // let deleteCameraList = this.cameraList.filter((item) => !!item);//fixsxyguanguan
      this.setProjectionVideoList(this.cameraList)

      /* if (idx === this.oldSize - 1) {
        this.cameraList = this.cameraList.filter((item) => !!item);
        this.saveCameraList(this.cameraList);

      } else if (idx === this.size - 1) {
        this.cameraList = this.cameraList.filter((item) => !!item);
        this.saveCameraList(this.cameraList);
        console.error('----dadd');
      } */

      this.$forceUpdate()
      if (flag) {
        //新增页签,删除视屏则执行
        this.$emit('delScreenVideo', idx)
      }

      //this.cameraList.splice(idx, 1);
      // console.error('removeScreenCamera', this.cameraList);
    },
    stopStreamMulHandle(cameraInfo) {
      if (cameraInfo.videoType === 1) {
        let cameraNums = [cameraInfo.cameraNum]
        let data = {
          cameraNums,
          streamType: -2
        }
        this.$api.home.stopStream(data).then(() => {
          /*  if (res.code) {
          console.log('停止高清推流');
        } */
        })
      }
    },
    pauseSCreenCamera(idx) {
      let obj =
        this.$refs['flvPlay' + idx] &&
        this.$refs['flvPlay' + idx][0]
      if (obj && !obj.flvPause) {
        obj.flv_pause()
        // console.error('haha');
      }
    },
    startScreenCameral(idx) {
      let obj =
        this.$refs['flvPlay' + idx] &&
        this.$refs['flvPlay' + idx][0]
      if (obj) {
        obj.flv_play()
        // console.error('haha');
      }
    },
    //高清停流

    cameraPlay(refObj, cameraInfo, change) {
      // console.log('获取播放器2');
      let that = this,
        cameraNum = cameraInfo.cameraNum
      // console.log('小投屏', cameraInfo); //这是
      // console.log(cameraNum);
      //m3u8Url 播放器暂时有问题
      let mediatype = that.$root.mediatype // 'flvUrl';//rtmpUr flvUrl m3u8Url
      let obj = {
        cameraNum: cameraNum, //record.data.cameraName,
        videoType: cameraInfo.videoType,
        mediatype: mediatype,
        source: cameraInfo.source
      }
      if (!this.$g.isDepart) {
        obj.playDomain = cameraInfo.playDomain
      }
      this.$api.home.getCameraPlayUrlTemp(obj).then(
        res => {
          if (res && res.code === 200) {
            // cameraInfo.loading = false;
            if (!res.data || !res.data.flv) {
              this.$message.error(
                '视频地址请求失败，' + res.message
              )
              //this.$emit("update:visible", false);
              return false
            }
            refObj.flv_Play(res.data.flv, true)
            if (!change) {
              //TODO: 增加摄像头访问记录
              // that.setCameraVisitRecords({
              //   cameraName: cameraInfo.cameraName,
              //   cameraNum: cameraInfo.cameraNum,
              //   organizationId: cameraInfo.organizationId,
              //   regionCode: cameraInfo.regionCode,
              //   roadId: cameraInfo.roadId
              //   //cameraType      : cameraInfo.cameraType,
              //   //classIfy        : cameraInfo.classIfy
              // });
            }
          } else {
            // cameraInfo.loading = false;
            this.$message.error('视频播放异常')
          }
        },
        error => {
          this.$message.error(error.msg)
        }
      )
    }
  }
}
// 再来
</script>

<style lang="less" scoped>
.ht {
  height: 240px;
}
.screen-camera-item {
  position: relative;
  height: 192px;
  // margin-bottom: 10px;
  // padding: 0 20px;
  > span {
    display: inline-block;
    width: 20px;
    position: absolute;
    top: 2px;
    left: 0;
    color: #fff;
    text-align: center;
  }
  &.width-1 {
    width: 100%;
    /*     height: 100%; */
    border-right: none;
    border-bottom: none;
  }
  &.width-4 {
    width: 50%;
    /*     height: 50%; */
    &:nth-child(2n) {
      border-right: none;
    }
  }
  &.width-6,
  &.width-9 {
    width: 33.3%;
    &:nth-child(3n) {
      border-right: none;
    }
  }
  &.width-12,
  &.width-16 {
    width: 25%;
    &:nth-child(4n) {
      border-right: none;
    }
  }
  &:hover > .screen-camera-delete,
  &:hover > .screen-camera-title,
  &:hover > .video-type {
    visibility: visible;
  }

  .screen-camera-delete {
    position: absolute;
    top: 5px;
    right: 8px;
    z-index: 15;
    text-align: center;
    visibility: hidden;
    i {
      font-size: 0.675rem;
      color: #fff;
    }
  }
  .screen-camera-title {
    line-height: 22px;
    background: #0060ff;
    color: #fff;
    padding: 0 5px;
    position: absolute;
    top: 5px;
    left: 25px;
    z-index: 2;
    width: 70%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .screen-camera-image {
    // border: 1px solid #236da1;
    padding: 4px;
    height: 100%;
    /*background-image: url("../../../assets/images/1.jpeg");
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: left center;*/
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.video-type {
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 99;
  background: #233a56;
}

.loading-spin {
  left: 0;
  top: 0;
}
.screen-item.currentBorder {
  border: 1px solid rgba(212, 34, 10) !important;
}
</style>
