<template>
  <div class="ht-style">
    <div
      class="btn-group btn-group-add flex justify-between items-center m-b"
    >
      <div class="flex items-center">
        <span
          :class="[
            'text-center pointer screen-index',
            { current: index === curIndex }
          ]"
          v-for="(item, index) in screenInfo"
          :key="index"
          @click="selectNewHandle(index)"
        >
          {{ index + 1 }}
          <a-icon
            type="minus"
            title="删除"
            v-show="index > 0"
            @click.native.stop="minusHandle(index)"
          />
        </span>
        <span
          class="text-center pointer"
          @click="addHandleNew()"
          >+</span
        >
      </div>
      <!--  <div
        class="flex items-center pointer text-lightblue"
        @click="viewHandle()"
      >
        <i class="icon-patrol pointer m-r-xs"></i>巡检预案
      </div> -->
      <div
        class="pointer"
        @click="viewMonitor"
        v-show="$g.isDepart"
      >
        <i
          title="监控"
          class="in-block icon-head icon-monitor ve-m m-r-xs"
        ></i>
      </div>
    </div>
    <div>
      <div
        class="flex justify-between items-center f12 text-lightblue"
      >
        <div>
          <a-select
            v-model="screenModeText"
            style="width: 120px"
            @change="handleChangeNew"
          >
            <a-select-option value="single">
              单分屏
            </a-select-option>
            <a-select-option value="four">
              4分屏
            </a-select-option>
            <a-select-option value="six">
              6分屏
            </a-select-option>
            <a-select-option value="nine">
              9分屏
            </a-select-option>
            <a-select-option value="twelve">
              12分屏
            </a-select-option>
            <a-select-option value="sixteen">
              16分屏
            </a-select-option>
          </a-select>
        </div>
        <div>
          <span
            class="flex items-center pointer"
            @click="openWindow(curIndex)"
            ><i
              class="icon-project m-r-xs"
              title="一键投屏"
            ></i
          ></span>
        </div>
      </div>
      <div class="screen-parent flex items-center">
        <split-screen
          ref="splitScreenInfo"
          :size="videoSizeNew"
          :winIndex="curIndex"
          v-if="reload"
          @handleSplitCamera="handleSplitCamera"
          @delScreenVideo="delScreenVideoHandle"
          @handleActiveIndex="activeIndexHandle"
        />
      </div>
    </div>
    <!-- <template v-for="(item, index) in screenArr">
      <div v-if="index === curIndex" :key="index">
        <div class="flex justify-between items-center f12 text-lightblue">
          <div>
            <a-select
              v-model="screenMode[index]"
              style="width: 120px"
              @change="handleChange"
            >
              <a-select-option value="single">
                单分屏
              </a-select-option>
              <a-select-option value="four">
                4分屏
              </a-select-option>
              <a-select-option value="six">
                6分屏
              </a-select-option>
              <a-select-option value="nine">
                9分屏
              </a-select-option>
              <a-select-option value="twelve">
                12分屏
              </a-select-option>
              <a-select-option value="sixteen">
                16分屏
              </a-select-option>
            </a-select>
          </div>
          <div>
            <span class="flex items-center pointer" @click="openWindow(index)"
              ><i class="icon-project m-r-xs" title="一键投屏"></i
            ></span>
          </div>
        </div>
        <div class="screen-parent flex items-center">
          <split-screen
            ref="splitScreenInfo"
            :size="videoSize[index]"
            :winIndex="index"
            @handleActiveIndex="activeIndexHandle"/>
        </div>
      </div>
    </template> -->
  </div>
</template>

<script>
/* import Single from './SingleComponent';
import Four from './FourComponent';
import Six from './SixComponent'; */
import SplitScreen from './SplitScreen'
import {
  /* clearStorage, */ getStorage,
  setStorage
} from '@/tools'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'HomeRightTop',
  data() {
    // let href = location.host
    // let protocol = location.protocol
    let ph = location.href.replace(location.hash, '')
    // console.error('papa', href, protocol, ph);
    return {
      screenSize: 1,
      screenArr: [1],
      screenInfo: [
        {
          index: 1,
          screenModeText: 'single',
          cameraList: []
        }
      ],
      screenModeText: 'single',
      videoSizeNew: 1,
      curIndex: 0,
      // screenMode: 'single',//fixneedOld
      screenMode: ['single'],
      url: [
        `${ph}#/single-window`,
        `${ph}#/single-window`,
        `${ph}#/single-window`,
        `${ph}#/single-window`
        /* 'http://localhost:8081/#/single-window',
        'http://localhost:8081/#/four-window',
        'https://segmentfault.com/q/1010000012623649',
        'https://www.51xuediannao.com/js/gg/ljtx.html', */
      ],
      // flag: 0,fixneedOld
      // videoSize: 1,fixneedOld
      videoSize: [1],
      currentActiveIndex: 0,
      // flagArr: new Array(4).fill(true),
      saveCameraListTemp: [], // save camera list
      reload: true
    }
  },
  components: {
    /* Single,
    Four,
    Six, */
    SplitScreen
  },
  mounted() {
    // console.error('---------------', location.host, location.protocol);
    this.setSplitScreenInfo(this.$refs['splitScreenInfo'])
  },
  computed: {
    ...mapState({
      screenActiveIndex: (state) =>
        state.home.screenActiveIndex
    })
  },
  watch: {},
  methods: {
    ...mapMutations([
      'setSplitScreenInfo',
      'setVideoSize',
      'setCurrentVideoIndex',
      'setScreenActiveIndex',
      'setProjectionVideoList',
      'setCurrentScreen'
    ]),
    handleSplitCamera(cameraList) {
      this.saveCameraListTemp[this.curIndex] = cameraList
      this.screenInfo[this.curIndex][
        'cameraList'
      ] = cameraList
    },
    delScreenVideoHandle(index) {
      // console.log('a',this.curIndex);
      this.saveCameraListTemp[this.curIndex]?.splice(
        index,
        1
      )
      console.log('d', this.saveCameraListTemp)
      this.screenInfo[this.curIndex][
        'cameraList'
      ] = this.saveCameraListTemp[this.curIndex]
    },
    activeIndexHandle(index) {
      this.screenActiveIndex[this.curIndex] = index //fixsxy
      this.setScreenActiveIndex(this.screenActiveIndex)
    },
    addHandleNew() {
      if (!this.hasPermission(1092101311)) {
        this.permissionWarning()
        return
      }
      if (Number(this.screenInfo.length) >= 4) {
        this.$message.error('最多只能新增4个分屏')
        return
      }
      // this.screenSize = ++this.screenSize;
      this.screenInfo.length += 1
      this.curIndex = this.screenInfo.length - 1
      /* this.screenArr = this.screenArr.concat(this.screenSize); */
      //设置分屏索引
      this.setCurrentScreen(this.curIndex)
      this.screenModeText = 'single'
      this.videoSizeNew = 1
      //确定每个屏幕下分屏数量
      this.$set(
        this.videoSize,
        this.curIndex,
        this.videoSizeNew
      )
      let cameraList =
        getStorage('cameraList')[this.curIndex] || []
      this.$set(this.screenInfo, this.curIndex, {
        index: 1,
        screenModeText: 'single',
        cameraList
      })
      this.$refs.splitScreenInfo.destroyVideoCom()
      //清除边框
      this.resetBorder()
      //设置一键清屏幕
      this.setProjectionVideoList([])
      this.$nextTick(() => {
        this.$refs['splitScreenInfo'] &&
          this.setSplitScreenInfo(
            this.$refs['splitScreenInfo']
          )
      })
    },

    minusHandle(order) {
      if (!this.hasPermission(1092101312)) {
        this.permissionWarning()
        return
      }

      this.$confirm({
        title: '提示',
        content: `确认删除第${order + 1}个分屏么?`,
        onOk: () => {
          this.$refs.splitScreenInfo.destroyVideoCom(false)
          this.reloadTimer && clearTimeout(this.reloadTimer)
          this.screenInfo.splice(order, 1)
          this.saveCameraListTemp.splice(order, 1)
          const {
            index,
            screenModeText
          } = this.screenInfo[0]
          this.curIndex = 0
          this.videoSizeNew = index
          this.screenModeText = screenModeText
          this.setProjectionVideoList(
            this.screenInfo[this.curIndex]['cameraList'] ||
              []
          )
          this.reload = false
          //设置分屏索引
          this.setCurrentScreen(this.curIndex)
          this.reloadTimer = setTimeout(() => {
            this.reload = true
            this.$nextTick(() => {
              /*  this.playLoad && clearTimeout(this.playLoad);
                this.playLoad = setTimeout(()=>{ */
              this.$refs['splitScreenInfo'] &&
                this.setSplitScreenInfo(
                  this.$refs['splitScreenInfo']
                )
              this.$refs.splitScreenInfo.setCameraList(
                this.screenInfo[this.curIndex]['cameraList']
              )
              /* },0); */
            })
          }, 0)
        },
        onCancel: () => {}
      })
    },
    selectNewHandle(order) {
      this.$refs.splitScreenInfo.destroyVideoCom(false)
      this.reloadTimer && clearTimeout(this.reloadTimer)
      this.curIndex = order
      this.reload = false
      this.reloadTimer = setTimeout(() => {
        this.reload = true
        const {
          index,
          screenModeText,
          cameraList
        } = this.screenInfo[order]

        this.$set(this.screenInfo, this.curIndex, {
          index,
          screenModeText,
          cameraList
        })
        //更新一键全屏数据
        this.setProjectionVideoList(
          this.screenInfo[this.curIndex]['cameraList'] || []
        )
        // this.screenInfo[this.curIndex]['cameraList'] = this.saveCameraListTemp;
        console.log(
          'hah',
          order,
          this.screenInfo[order],
          index,
          screenModeText,
          this.screenInfo[this.curIndex]['cameraList'],
          this.saveCameraListTemp
        )
        this.screenModeText = screenModeText
        this.videoSizeNew = index
        //确定每一个分屏下屏幕数量
        this.$set(
          this.videoSize,
          this.curIndex,
          this.videoSizeNew
        )
        //设置分屏索引
        this.setCurrentScreen(this.curIndex)
        this.$nextTick(() => {
          this.$refs['splitScreenInfo'] &&
            this.setSplitScreenInfo(
              this.$refs['splitScreenInfo']
            )
          /*   this.playLoad && clearTimeout(this.playLoad);
             this.playLoad = setTimeout(()=>{ */
          this.$refs.splitScreenInfo.setCameraList(
            this.screenInfo[this.curIndex]['cameraList']
          )
          /*   },0);
             //this.$refs.splitScreenInfo.setCameraList(this.screenInfo[this.curIndex]['cameraList'] );*/
        })
      }, 0)
    },

    resetBorder() {
      this.currentActiveIndex = 0
      this.screenActiveIndex[
        this.curIndex
      ] = this.currentActiveIndex
      // this.setScreenActiveIndex(this.currentActiveIndex);//fixsxy以前
      this.setScreenActiveIndex(this.screenActiveIndex)
      this.$refs.splitScreenInfo.setActiveBorder(
        this.currentActiveIndex
      )
      // this.$refs.splitScreenInfo[0].setActiveBorder(this.currentActiveIndex);fix以前
    },
    handleChangeNew(value) {
      // console.log(`selected ${value}`);
      //清除边框
      this.resetBorder()
      switch (value) {
        case 'single':
          // this.videoSize = 1;
          this.videoSizeNew = 1
          this.screenModeText = 'single'

          break
        case 'four':
          // this.videoSize = 4;
          this.videoSizeNew = 4
          this.screenModeText = 'four'
          break
        case 'six':
          this.videoSizeNew = 6
          this.screenModeText = 'six'
          break
        case 'nine':
          this.videoSizeNew = 9
          this.screenModeText = 'nine'
          break
        case 'twelve':
          this.videoSizeNew = 12
          this.screenModeText = 'twelve'
          break
        case 'sixteen':
          this.videoSizeNew = 16
          this.screenModeText = 'sixteen'
          break
      }
      //清空一件投屏
      this.setProjectionVideoList([])
      this.$set(
        this.videoSize,
        this.curIndex,
        this.videoSizeNew
      )
      this.setVideoSize(this.videoSize)
      this.setCurrentVideoIndex(this.curIndex)
      let { cameraList } = this.screenInfo[this.curIndex]
      this.$set(this.screenInfo, this.curIndex, {
        index: this.videoSizeNew,
        screenModeText: this.screenModeText,
        cameraList: cameraList || []
      })
      console.log('b', cameraList)
      this.$refs.splitScreenInfo.destroyVideoCom(false)
      //截取要展示的摄像机数据
      const showCameraList = cameraList.slice(
        0,
        this.videoSizeNew
      )
      console.log('c', showCameraList)

      if (showCameraList.length) {
        this.$refs.splitScreenInfo.setCameraList(
          showCameraList
        )
      }
    },
    handleChange(value) {
      // console.log(`selected ${value}`);
      //清除边框
      this.resetBorder()
      switch (value) {
        case 'single':
          // this.videoSize = 1;
          this.$set(this.videoSize, this.curIndex, 1)
          this.$set(
            this.screenMode,
            this.curIndex,
            'single'
          )
          this.setVideoSize(this.videoSize)
          this.setCurrentVideoIndex(this.curIndex)
          //清空一件投屏
          this.setProjectionVideoList([])
          // this.$refs.splitScreenInfo[0].
          break
        case 'four':
          // this.videoSize = 4;
          this.$set(this.videoSize, this.curIndex, 4)
          this.$set(this.screenMode, this.curIndex, 'four')
          this.setVideoSize(this.videoSize)
          this.setCurrentVideoIndex(this.curIndex)
          //清空一件投屏
          this.setProjectionVideoList([])
          break
        case 'six':
          // this.videoSize = 6;
          this.$set(this.videoSize, this.curIndex, 6)
          this.$set(this.screenMode, this.curIndex, 'six')
          this.setVideoSize(this.videoSize)
          this.setCurrentVideoIndex(this.curIndex)
          //清空一件投屏
          this.setProjectionVideoList([])
          break
        case 'nine':
          // this.videoSize = 9;
          this.$set(this.videoSize, this.curIndex, 9)
          this.$set(this.screenMode, this.curIndex, 'nine')
          this.setVideoSize(this.videoSize)
          this.setCurrentVideoIndex(this.curIndex)
          //清空一件投屏
          this.setProjectionVideoList([])
          break
        case 'twelve':
          // this.videoSize = 12;
          this.$set(this.videoSize, this.curIndex, 12)
          this.$set(
            this.screenMode,
            this.curIndex,
            'twelve'
          )
          this.setVideoSize(this.videoSize)
          this.setCurrentVideoIndex(this.curIndex)
          //清空一件投屏
          this.setProjectionVideoList([])
          break
        case 'sixteen':
          // this.videoSize = 16;
          this.$set(this.videoSize, this.curIndex, 16)
          this.$set(
            this.screenMode,
            this.curIndex,
            'sixteen'
          )
          this.setVideoSize(this.videoSize)
          this.setCurrentVideoIndex(this.curIndex)
          //清空一件投屏
          this.setProjectionVideoList([])
          break
      }
    },
    /**
     * 判断有一个是否正在投屏
     */
    hasOneScreen(flagArr) {
      let bool = true

      for (let i = 0; i < flagArr.length; i++) {
        if (!flagArr[i]) {
          bool = false
          break
        }
      }
      return bool
    },
    openWindow(index) {
      if (!this.hasPermission(1092101310)) {
        this.permissionWarning()
        return
      }

      let flagArr = getStorage('flagArr')
      // debugger;
      /* if (!flagArr[index]) {
        this.$message.error('只能投屏一次！');
        return;
      } fixsxy2022-1-4修改后的逻辑（保证只要有一个投屏了，其他均不可投屏）*/
      if (!this.hasOneScreen(flagArr)) {
        this.$message.error('只能投屏一次！')
        return
      }
      //关闭当前摄像机
      this.pauseCurrentVideo()
      /* let patrolCameral = getStorage('isPatrolCameral') || 'false';
      if (patrolCameral == 'true') {
        this.$message.error('轮巡预案正在进行不能投屏！');
        return;
      }fixsxy */
      let patern = this.url[index].substring(
        this.url[index].indexOf('#') + 1
      )
      let url = this.url[index].replace(
        patern,
        `${this.screenInfo[index]['screenModeText']}-window`
      )
      /* this.$set(this.flagArr, index, false); */
      //存储在localStorage里面
      flagArr[index] = false
      setStorage('flagArr', flagArr)
      window.open(`${url}?index=${index}`, '_blank')
    },
    addHandleNeedOld() {
      if (Number(this.screenSize) >= 4) {
        this.$message.error('最多只能新增4个分屏')
        return
      }
      this.screenSize = ++this.screenSize
      this.curIndex++
      // this.flag = 0;fixsxy前

      this.screenArr = this.screenArr.concat(
        this.screenSize
      )
      this.url = new Array(4).fill(this.url[0])
    },

    viewMonitor() {
      this.$router.push({
        path: '/home/project-screen'
      })
    },
    pauseCurrentVideo() {
      let size = this.screenInfo[this.curIndex]['index']
      for (let i = 0; i < size; i++) {
        // this.$refs.splitScreenInfo[0].pauseSCreenCamera(i);
        this.$refs.splitScreenInfo.pauseSCreenCamera(i)
      }
    }
  },
  deactivated() {
    this.pauseCurrentVideo()
    this.reloadTimer && clearTimeout(this.reloadTimer)
    /*   this.playLoad && clearTimeout(this.playLoad); */
  }
}
</script>

<style lang="less" scoped>
.ht-style {
  span.screen-index {
    position: relative;

    .anticon {
      position: absolute;
      &.anticon-minus {
        background-image: linear-gradient(
          180deg,
          #4679bf,
          transparent
        );
        opacity: 0.5;
        right: 0;
        top: 0;
        transition: 0.1s;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
</style>
<style scoped>
.btn-group-add span {
  width: 48px;
  height: 30px;
  line-height: 30px;
}
.icon-project {
  background: url('../../../assets/resource/icon-project.png')
    no-repeat;
  background-size: 100% 100%;
  width: 16px;
  height: 13px;
}
.screen-parent {
  height: 70%;
}
.ht-style {
  height: 272px;
}
</style>
