<template>
  <div class="full-width full-height">
    <vue-scroll :ops="$root.scrollOpsY" style="height:calc(100% - 30px)">
      <div class="p-sm">
        <div class="flex flex-end m-b-sm">
          <span class="pointer text-lightblue" @click="clearHistory"
            >一键清空</span
          >
        </div>

        <div
          class="flex items-center m-b-sm"
          v-for="item in videoList"
          :key="item.id"
        >
          <span
            :class="{
              'm-r-xs icon-style': true,
              online: String(item.onlineStatus) === '1',
              offline: String(item.onlineStatus) === '0',
              error: String(item.onlineStatus) === '2',
              yunOffline: String(item.onlineStatus) === '0' && $g.treeDefinate,
            }"
          ></span>
          <div class="wd100">
            <div
              class="flex full-width justify-between items-center text-lightblue f14"
            >
              <div class="wd80 pointer" @click="selectItem(item)">
                <span class="text-lightwhite block f12">{{
                  item.historyTime
                }}</span>
                <span
                  class="m-r-sm block full-width ellipsis"
                  :title="item.cameraNames"
                  >{{ item.cameraNames }}</span
                >
              </div>
              <a-icon
                type="rest"
                class="pointer"
                @click="delVideoHistory(item)"
              />
            </div>
          </div>
        </div>
      </div>
    </vue-scroll>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
export default {
  name: 'VideoHistory',
  data() {
    return {};
  },
  computed: {
    ...mapState({
      videoList: (state) => state.aiCenter.videoList,
      amapIns: (state) => state.home.amapIns,
    }),
  },
  watch: {},
  created() {
    this.getCameraHistory();
  },
  methods: {
    delVideoHistory(item) {
      let data = {
        instructionsDel: {
          module: '重点关注',
          page: '调阅历史记录',
          feature: '删除调阅记录',
          description: `删除调阅记录: ${item.cameraName}`,
        },
        params: [item.id],
      };
      this.$confirm({
        title: '提示',
        content: '你确定删除该条记录吗?',
        onOk: () => {
          this.$api.aiCenterY.deleteCameraHistory(data).then((res) => {
            if (res.code === 200) {
              let newVideoList = this.videoList.filter(
                (dataItem) => item.id !== dataItem.id
              );
              this.setVideoList(newVideoList);
              this.$message.success('删除一条视频记录成功');
            }
          });
        },
      });
    },
    clearHistory() {
      let data = this.videoList.map((item) => item.id);
      let newData = {
        instructionsDel: {
          module: '重点关注',
          page: '调阅历史记录',
          feature: '一键清空',
          description: '一键清空:历史调阅记录',
        },
        params: data,
      };
      this.$confirm({
        title: '提示',
        content: '你确定清空该历史记录吗?',
        onOk: () => {
          this.$api.aiCenterY.deleteCameraHistory(newData).then((res) => {
            if (res.code === 200) {
              this.setVideoList([]);
              this.$message.success('清空视频记录成功');
            }
          });
        },
      });
    },
    selectItem(item) {
      /* if (String(item.onlineStatus) === '1') { */
      //点击摄像头
      if (item.otherOrganizationId === 1) {
        this.$message.error('暂无观看权限！');
        return;
      }
      let cameraInfo = item;
      // console.error('ppp-----', cameraInfo);
      this.amapIns.cameraInfoWindowShow({
        data: {
          position: [cameraInfo.longitude, cameraInfo.latitude],
          onlineStatus: cameraInfo.onlineStatus,
          cameraName: cameraInfo.cameraName,
          cameraId: cameraInfo.cameraId,
          cameraNum: cameraInfo.cameraNum,
          organizationId: cameraInfo.organizationId,
          regionCode: cameraInfo.regionCode,
          roadId: cameraInfo.roadId,
          kmPile: cameraInfo.kmPile,
          hmPile: cameraInfo.hmPile,
          classify: cameraInfo.classify,
          cameraType: cameraInfo.cameraType,
          zoomlevel: cameraInfo.zoomLevel,
          source: cameraInfo.source,
          khPile: cameraInfo.khPile,
          collectFlag: cameraInfo.favoriteFlag,
          /* resolutionValue: cameraInfo.organizationId.startsWith('9901004075')
            ? 1
            : 0, */
          resolutionValue: cameraInfo.transcodingId === '1354354698693513216' ? 1 : 0
        },
      });

      this.amapIns.addVideoMarker(item);
      /*  } else {
        this.$message.error('该摄像机不在线');
      } */
    },
    ...mapMutations(['setVideoList']),
    ...mapActions(['getCameraHistory']),
  },
};
</script>

<style scoped>
.wd100 {
  width: calc(100% - 20px);
}
.wd80 {
  width: 80%;
}
.icon-style {
  flex-shrink: 0;
  margin-left: 10px;
}
</style>
